<template>
  <div>
    <div class="w-100 bg-light p-1 mb-2 rounded d-flex justify-content-between">
      <p class="mb-0">
        Судебная работа
      </p>
      <BButton
        v-if="skDebtJudicalWorkFilterdDate.CONTENT_TYPE"
        style="padding: 3px"
        :variant="!editItem ? 'warning' : 'light'"
        class="rounded-circle mr-50"
        @click="editItem = !editItem"
      >
        <feather-icon
          icon="EditIcon"
        />
      </BButton>
    </div>

    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav">
          <p>Претензии:</p>
          <p
            v-if="skDebtJudicalWorkFilterdDate.CONTENT_TYPE"
            v-b-modal.modalClaims
            class="a__click"
            @click="openClaimsModal"
          >
            {{ skDebtJudicalWorkClaimsModal.count }}
            Претензии
          </p>
        </div>
        <div class="filds__nav">
          <p>Вид:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtJudicalWorkFilterdDate.JUD_TYPE"
            label="title"
            class="w-100"
            :reduce="p => p.value"
            :options="JUD_TYPE_LIST"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ getJudType }}
          </p>
        </div>
        <div class="filds__nav">
          <p> Дата формирования:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="skDebtJudicalWorkFilterdDate.PETITION_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            <span>{{ skDebtJudicalWorkFilterdDate.PETITION_DATE }}
            </span>
          </p>
        </div>
        <div class="filds__nav">
          <p>Дата отправки:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="skDebtJudicalWorkFilterdDate.JUD_DATE_SENT"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            {{ skDebtJudicalWorkFilterdDate.JUD_DATE_SENT }}
          </p>
        </div>
        <div class="filds__nav">
          <p>Статус:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtJudicalWorkFilterdDate.STATUS"
            label="title"
            class="w-100"
            :reduce="p => p.value"
            :options="JUD_STATUS_LIST"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ getJudStatus }}
          </p>
        </div>
        <div class="filds__nav">
          <p>ИД:</p>
          <p
            v-if="skDebtJudicalWorkFilterdDate.EXECUTIVE_DOCUMENT"
            class="a__click"
            @click="activeTabChange"
          >
            ИД от {{ skDebtJudicalWorkFilterdDate.EXECUTIVE_DOCUMENT.EXECUTIVE_DATE }}
          </p>
        </div>
        <div class="filds__nav">
          <p>Комментарии:</p>
          <p
            v-if="skDebtJudicalWorkFilterdDate.CONTENT_TYPE"
            class="a__click"
            @click="openModal('comments')"
          >
            Комментариев
          </p>
        </div>
        <div class="filds__nav">
          <p>Документы:</p>
          <p
            v-if="skDebtJudicalWorkFilterdDate.CONTENT_TYPE"
            class="a__click"
            @click="openDocModal"
          >
            Документы
          </p>
        </div>
        <div class="filds__nav">
          <p>Номер судебного дела:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_NUMBER"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Способ отправления:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtJudicalWorkFilterdDate.SEND_TYPE"
            label="title"
            class="w-100"
            :reduce="p => p.value"
            :options="SEND_TYPE"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ getJudSendType }}
          </p>
        </div>
        <div class="filds__nav">
          <p>Регистрационный номер:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.REG_NUMBER"
            :plaintext="editItem"
            value="registration_number"
          />
        </div>
        <div class="filds__nav">
          <p>Статус электронного дела:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtJudicalWorkFilterdDate.ELECTRONIC_STATUS"
            label="title"
            class="w-100"
            :reduce="p => p.value"
            :options="ELECTRONIC_STATUS"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ getJudElictronicStatus }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="cancel"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateJudicalWork"
      >
        Сохранить
      </b-button>
    </div>

    <!--    ClaimsModal-->
    <TheClaimsModal
      ref="claims"
      @claimsRefresh="claimsRefresh"
    />
    <!--    ClaimsModal end -->

    <!--//comment modal-->
    <TheCommentsModal
      ref="comments"
      :comment="comments"
      :comment-message="commentDebt.results"
      @createComment="createComment"
      @refresh="refresh"
      @deleteComment="deleteComment"
    />
    <!--//comment modal end-->

    <!--//document modal-->
    <theDocModal
      ref="documents"
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
    <!--//document modal end-->
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import TheCommentsModal from '@components/modules/TheCommentsModal.vue'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import areYouSure from '@/mixins/areYouSure'
import theDocModal from '@/components/modalDocDebts/theDocModal.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import TheClaimsModal from './TheClaimsModal.vue'

export default {
  components: {
    BButton,
    TheClaimsModal,
    TheCommentsModal,
    theDocModal,
    BFormInput,
    vSelect,
    flatPickr,
  },

  mixins: [areYouSure],

  data() {
    return {
      type: 'judical',
      editItem: true,
      comments: {
        message: '',
      },
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },

  computed: {
    ...mapState('cases', [
      'ELECTRONIC_STATUS',
      'SEND_TYPE',
      'JUD_STATUS_LIST',
      'JUD_TYPE_LIST',
      'skDebtJudicalWorkFilterdDate',
      'commentDebt',
      'debtDocument',
      'skDebtJudicalWorkClaimsModal',
    ]),
    tabActive: {
      get() { return this.$store.state.cases.tabActive },
      set(value) { this.$store.commit('cases/SET_ACTIVE_TAB', value) },
    },
    dosser: {
      get() { return this.$store.state.cases.dosserId },
      set(value) {
        this.$store.commit('cases/SET_DOSSER_ID', value)
      },
    },
    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },

    getJudType() {
      let text = ''
      // eslint-disable-next-line default-case
      switch (this.skDebtJudicalWorkFilterdDate.JUD_TYPE) {
        case 'perviy_isk':
          text = 'Первый иск'
          break
        case 'vtoroy_isk':
          text = 'Второй иск'
          break
        case 'perviy_sudebnyy_prikaz':
          text = 'Первый судебный приказ'
          break
        case 'vtoroy_sudebnyy_prikaz':
          text = 'Второй судебный приказ'
          break
        case 'dlyashiesya_trebovaniya':
          text = 'Длящиеся требования'
          break
        case 'isk_na_YU':
          text = 'Иск на ЮУ'
          break
        case 'index1':
          text = 'Индексация 1'
          break
        case 'index2':
          text = 'Индексация 2'
          break
      }
      return text
    },

    getJudStatus() {
      let text2 = ''
      // eslint-disable-next-line default-case
      switch (this.skDebtJudicalWorkFilterdDate.STATUS) {
        case 'claim':
          text2 = 'Претензия'
          break
        case 'doc_preparation':
          text2 = 'Подготовка документов'
          break
        case 'judicial_proceeding':
          text2 = 'Судебное производство'
          break
        case 'satisfied':
          text2 = 'Удовлетворено'
          break
        case 'partially_satisfied':
          text2 = 'Удовлетворено частично'
          break
        case 'refused':
          text2 = 'Отказано'
          break
        case 'disputing':
          text2 = 'Оспаривается'
          break
        case 'executive_document':
          text2 = 'Исполнительный документ'
          break
      }
      return text2
    },

    getJudSendType() {
      let text3 = ''
      // eslint-disable-next-line default-case
      switch (this.skDebtJudicalWorkFilterdDate.SEND_TYPE) {
        case 'electronic':
          text3 = 'Электронно'
          break
        case 'post':
          text3 = 'Почтовое отправление'
          break
        case 'judicial_proceeding':
          text3 = 'Судебное производство'
          break
        case 'purposely':
          text3 = 'Нарочно'
          break
      }
      return text3
    },

    getJudElictronicStatus() {
      let text4 = ''
      // eslint-disable-next-line default-case
      switch (this.skDebtJudicalWorkFilterdDate.ELECTRONIC_STATUS) {
        case 'sent_to_court':
          text4 = 'Отправлено в суд'
          break
        case 'registered':
          text4 = 'Зарегистрировано в суде'
          break
        case 'judge':
          text4 = 'Передано на рассмотрение судье'
          break
        case 'technical_deny':
          text4 = 'Технический отказ'
          break
      }
      return text4
    },
  },

  methods: {
    ...mapActions('cases', [
      'FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE',
      'UPDATE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE',
      'CREATE_COMMENT',
      'FETCH_COMMENT_DEBT',
      'DELETE_COMMENT',
      'FETCH_DEBT_DOCUMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
      'DELETE_DEBT_DOCUMENT',
      'FETCH_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL',
    ]),

    activeTabChange(e) {
      this.dosser = this.skDebtJudicalWorkFilterdDate.EXECUTIVE_DOCUMENT
      this.tabActive = 'tab3'
    },

    cancel() {
      this.FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({ id: this.skDebtJudicalWorkFilterdDate.id })
      this.editItem = true
    },

    updateJudicalWork() {
      delete this.skDebtJudicalWorkFilterdDate.JUD_GUID
      delete this.skDebtJudicalWorkFilterdDate.PETITION_DATE
      this.UPDATE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({
        id: this.skDebtJudicalWorkFilterdDate.id,
        ...this.skDebtJudicalWorkFilterdDate,
      })
        .then(() => {
          this.editItem = true
          this.$_okToast()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    openModal(modal) {
      const enm = ['documents', 'comments']
      if (enm.includes(modal)) {
        this.$refs[modal].open()
      } else {
        console.error('Нет такого модального окна ', JSON.stringify(modal))
      }
    },

    // ******************* comments methods *********************** //
    createComment() {
      this.CREATE_COMMENT({
        author: this.getUserId,
        content_type: this.skDebtJudicalWorkFilterdDate.CONTENT_TYPE,
        object_id: this.skDebtJudicalWorkFilterdDate.id,
        ...this.comments,
      })
        .then(() => {
          this.refresh()
          this.$_okToast()
          this.comments.message = ''
        })
    },

    refresh() {
      this.FETCH_COMMENT_DEBT({
        content_type: this.skDebtJudicalWorkFilterdDate.CONTENT_TYPE,
        object_id: this.skDebtJudicalWorkFilterdDate.id,
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
          }
        })
    },
    // ***************** comments methods end ********************* //

    // *********************** document *****************************//
    openDocModal() {
      this.$bvModal.show('modalDoc')
      this.docRefresh()
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }

      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.skDebtJudicalWorkFilterdDate.CONTENT_TYPE)
      formData.append('object_id', this.skDebtJudicalWorkFilterdDate.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEditDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.skDebtJudicalWorkFilterdDate.CONTENT_TYPE)
      formData.append('object_id', this.skDebtJudicalWorkFilterdDate.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.file_title = null
        this.formData.tags = []
        this.$bvModal.hide('addEditDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.skDebtJudicalWorkFilterdDate.CONTENT_TYPE,
        object_id: this.skDebtJudicalWorkFilterdDate.id,
        ...data,
      })
    },
    // ******************** document end **************************//

    // ******************** claims modal **************************//
    openClaimsModal() {
      this.$bvModal.show('modalClaims')
      this.claimsRefresh()
    },

    claimsRefresh(data) {
      this.FETCH_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL({ id: this.skDebtJudicalWorkFilterdDate.id, ...data })
    },
    // ******************** claims modal end **************************//
  },
}
</script>
