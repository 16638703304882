<!--<template>-->
<!--  <BModal-->
<!--    ref="modal"-->
<!--    size="xl"-->
<!--    centered-->
<!--    title="Документы"-->
<!--    ok-variant="info"-->
<!--    ok-title="Да"-->
<!--    cancel-title="Отмена"-->
<!--    hide-footer-->
<!--    responsive-->
<!--    no-close-on-backdrop-->
<!--    @show="openShowModal"-->
<!--  >-->
<!--    <div style="overflow-x: auto">-->
<!--      <BTable-->
<!--        class="my-2"-->
<!--        :items="docvalue"-->
<!--        :fields="fields"-->
<!--        show-empty-->
<!--        empty-text="Нет данных"-->
<!--      >-->
<!--        <template #cell(created_date)="props">-->
<!--          <VcDatePicker-->
<!--            v-if="checkItemId(props.item)"-->
<!--            v-model="formDatas.created_date"-->
<!--            show-format="YYYY-MM-DD"-->
<!--          />-->
<!--          <span v-else>{{ props.item.created_date }}</span>-->
<!--        </template>-->

<!--        <template #cell(date_document)="props">-->
<!--          <VcDatePicker-->
<!--            v-if="checkItemId(props.item)"-->
<!--            v-model="formDatas.date_document"-->
<!--            show-format="YYYY-MM-DD"-->
<!--          />-->
<!--          <span v-else>{{ props.item.date_document }}</span>-->
<!--        </template>-->

<!--        &lt;!&ndash;        <template #cell(type)="props">&ndash;&gt;-->
<!--        &lt;!&ndash;          <VSelect&ndash;&gt;-->
<!--        &lt;!&ndash;            v-if="checkItemId(props.item)"&ndash;&gt;-->
<!--        &lt;!&ndash;            v-model="formData.type"&ndash;&gt;-->
<!--        &lt;!&ndash;            class="select-w-z-index"&ndash;&gt;-->
<!--        &lt;!&ndash;            :clearable="false"&ndash;&gt;-->
<!--        &lt;!&ndash;            :options="types"&ndash;&gt;-->
<!--        &lt;!&ndash;            label="title"&ndash;&gt;-->
<!--        &lt;!&ndash;          />&ndash;&gt;-->
<!--        &lt;!&ndash;          <span v-else> {{ props.item.types }}</span>&ndash;&gt;-->
<!--        &lt;!&ndash;        </template>&ndash;&gt;-->
<!--        <template #cell(file)="props">-->
<!--          <BFormFile-->
<!--            v-if="checkItemId(props.item)"-->
<!--            v-model="formDatas.file"-->
<!--          />-->
<!--          <a-->
<!--            v-else-->
<!--            href="#"-->
<!--          >файлы</a>-->
<!--        </template>-->

<!--        <template #cell(title)="props">-->
<!--          <BFormInput-->
<!--            v-if="checkItemId(props.item)"-->
<!--            v-model="formDatas.title"-->
<!--          />-->
<!--          <span v-else>{{ props.item.title }}</span>-->
<!--        </template>-->

<!--        <template #cell(comment)="props">-->
<!--          <BFormInput-->
<!--            v-if="checkItemId(props.item)"-->
<!--            v-model="formDatas.comment"-->
<!--          />-->
<!--          <span v-else>{{ props.item.comment }}</span>-->
<!--        </template>-->

<!--        <template #cell(actions)="props">-->
<!--          <div v-if="props.item.id !== formData.id">-->
<!--            <BButton-->
<!--              class="p-50 mr-1"-->
<!--              variant="warning"-->
<!--              @click="editRow(props.item)"-->
<!--            >-->
<!--              <feather-icon icon="EditIcon" />-->
<!--            </BButton>-->
<!--            <BButton-->
<!--              class="p-50"-->
<!--              variant="danger"-->
<!--              @click="$emit('deleteDataDocument',props.item.id)"-->
<!--            >-->
<!--              <feather-icon icon="XIcon" />-->
<!--            </BButton>-->
<!--          </div>-->
<!--        </template>-->
<!--      </BTable>-->
<!--    </div>-->

<!--    <div class="d-flex justify-content-center">-->
<!--      <BButton-->
<!--        v-if="!formData.active"-->
<!--        class="mr-2"-->
<!--        variant="info"-->
<!--        @click="addNewData"-->
<!--      >-->
<!--        Добавить имущество-->
<!--      </BButton>-->
<!--      <div v-else>-->
<!--        <BButton-->
<!--          class="mr-2"-->
<!--          variant="warning"-->
<!--          @click="createDoc"-->
<!--        >-->
<!--          Сохранить-->
<!--        </BButton>-->
<!--        <BButton-->
<!--          class="border"-->
<!--          variant="light"-->
<!--          @click="clearData"-->
<!--        >-->
<!--          Отменить-->
<!--        </BButton>-->
<!--      </div>-->
<!--    </div>-->
<!--    document-->
<!--    <TheCommentsModal-->
<!--      ref="comments"-->
<!--    />-->
<!--    <pre>-->
<!--      {{ formData }}-->
<!--    </pre>-->
<!--  </BModal>-->
<!--</template>-->

<!--<script>-->
<!--import {-->
<!--  BModal,-->
<!--  BFormInput,-->
<!--  BFormFile,-->
<!--  BTable,-->
<!--  BButton,-->
<!--} from 'bootstrap-vue'-->

<!--import VcDatePicker from '@/components/VcDatePicker.vue'-->
<!--import TheCommentsModal from './TheCommentsModal.vue'-->

<!--const defData = {-->
<!--  created_date: null,-->
<!--  date_document: null,-->
<!--  file: null,-->
<!--  title: null,-->
<!--  comment: null,-->
<!--}-->

<!--export default {-->
<!--  name: 'TheDebtorIncomeModal',-->
<!--  components: {-->
<!--    BModal,-->
<!--    BFormInput,-->
<!--    BFormFile,-->
<!--    BTable,-->
<!--    BButton,-->
<!--    VcDatePicker,-->
<!--    TheCommentsModal,-->
<!--  },-->
<!--  props: {-->
<!--    formDatas: {-->
<!--      type: Object,-->
<!--      default: () => {},-->
<!--    },-->
<!--    docvalue: {-->
<!--      type: Array,-->
<!--      default: () => [],-->
<!--    },-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      formData: {-->
<!--        active: false,-->
<!--      },-->
<!--      fields: [-->
<!--        {-->
<!--          label: 'Дата создания',-->
<!--          key: 'created_date',-->
<!--          sortable: true,-->
<!--        },-->
<!--        {-->
<!--          label: 'Дата документа',-->
<!--          key: 'date_document',-->
<!--        },-->
<!--        {-->
<!--          label: 'Файлы',-->
<!--          key: 'file',-->
<!--        },-->
<!--        {-->
<!--          label: 'Полезная информация',-->
<!--          key: 'title',-->
<!--        },-->
<!--        {-->
<!--          label: 'Комментарий',-->
<!--          key: 'comment',-->
<!--        },-->
<!--        {-->
<!--          label: 'Действия',-->
<!--          key: 'actions',-->
<!--        },-->
<!--      ],-->
<!--      propertiesList: [-->
<!--        {-->
<!--          id: 1,-->
<!--          created_date: '',-->
<!--          date_document: '',-->
<!--          file: [],-->
<!--          title: 'info',-->
<!--          comment: 'comment',-->
<!--          actions: 'actions',-->
<!--        },-->
<!--      ],-->

<!--      types: [-->
<!--        {-->
<!--          title: 'Наследство',-->
<!--          value: 'legacy',-->
<!--        },-->
<!--      ],-->
<!--      statuses: [-->
<!--        {-->
<!--          title: 'Продано',-->
<!--          value: 'sold',-->
<!--        },-->
<!--        {-->
<!--          title: 'Приобретено',-->
<!--          value: 'purchased',-->
<!--        },-->
<!--      ],-->
<!--      reg_organs: [-->
<!--        {-->
<!--          title: 'Расрочка',-->
<!--          value: 'loan',-->
<!--        },-->
<!--      ],-->
<!--    }-->
<!--  },-->

<!--  mounted() {-->
<!--    this.docvalue.forEach(item => {-->
<!--      item.edit = false-->
<!--    })-->
<!--  },-->

<!--  methods: {-->
<!--    open() {-->
<!--      this.$refs.modal.show()-->
<!--    },-->
<!--    close() {-->
<!--      this.$refs.modal.hide()-->
<!--    },-->
<!--    openShowModal() {-->
<!--      setTimeout(() => {-->
<!--        this.$emit('docRefresh')-->
<!--        this.formData = { ...this.formDatas }-->
<!--      }, 1000)-->
<!--    },-->
<!--    // openCommentModal() {-->
<!--    //   this.$refs.comments.open()-->
<!--    // },-->
<!--    editRow(item) {-->
<!--      this.clearEptyObj()-->
<!--      // const exportData = {-->
<!--      //   active: true,-->
<!--      //   ...item,-->
<!--      // }-->

<!--      this.formData = {-->
<!--        ...item,-->
<!--        active: true,-->
<!--      }-->

<!--      this.$emit('setFormData', this.formData)-->

<!--      // this.formData = { ...this.formDatas }-->

<!--      // console.log('Formdatas', this.formDatas)-->
<!--    },-->
<!--    checkItemId(item) {-->
<!--      return item.id === this.formData.id-->
<!--    },-->
<!--    createDoc() {-->
<!--      this.clearEptyObj()-->
<!--      this.formData = {-->
<!--        active: false,-->
<!--        ...defData,-->
<!--      }-->
<!--      this.$emit('createDoc', this.formData)-->
<!--    },-->
<!--    addNewData() {-->
<!--      this.$emit('setFormData', this.formData)-->
<!--      this.formData = {-->
<!--        id: 0,-->
<!--        active: true,-->
<!--        ...defData,-->
<!--      }-->
<!--      this.docvalue.push({-->
<!--        id: 0,-->
<!--        active: true,-->
<!--        ...defData,-->
<!--      })-->
<!--    },-->
<!--    clearEptyObj() {-->
<!--      this.docvalue = this.docvalue.filter(e => parseInt(e.id))-->
<!--    },-->
<!--    clearData() {-->
<!--      this.clearEptyObj()-->
<!--      this.formData = {-->
<!--        active: false,-->
<!--        ...defData,-->
<!--      }-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->
<!--<style lang="scss">-->
<!--@import "@core/scss/vue/libs/vue-select.scss";-->
<!--.select-w-z-index {-->
<!--  min-width: 250px;-->
<!--  z-index: 9999;-->
<!--}-->
<!--</style>-->
<template>
  <BModal
    ref="modal"
    size="xl"
    centered
    title="Документы"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    responsive
    no-close-on-backdrop
  >
    <div style="overflow-x: auto">
      <BTable
        class="my-2"
        :items="propertiesList"
        :fields="fields"
        show-empty
        empty-text="Нет данных"
      >
        <template #cell(date)="props">
          <VcDatePicker
            v-if="checkItemId(props.item)"
            v-model="formData.date"
            show-format="YYYY.MM.DD"
          />
          <span v-else>{{ props.item.date }}</span>
        </template>
        <template #cell(date_doc)="props">
          <VcDatePicker
            v-if="checkItemId(props.item)"
            v-model="formData.date_doc"
            show-format="YYYY.MM.DD"
          />
          <span v-else>{{ props.item.date_doc }}</span>
        </template>
        <template #cell(type)="props">
          <VSelect
            v-if="checkItemId(props.item)"
            v-model="formData.type"
            class="select-w-z-index"
            :clearable="false"
            :options="types"
            label="title"
          />
          <span v-else> {{ props.item.types }}</span>
        </template>
        <template #cell(files)="props">
          <BFormFile
            v-if="checkItemId(props.item)"
            v-model="formData.files"
          />
          <span v-else>файлы</span>
        </template>
        <template #cell(info)="props">
          <BFormCheckbox
            v-if="checkItemId(props.item)"
            v-model="formData.info"
          />
          <span v-else>{{ props.item.info }}</span>
        </template>
        <template #cell(comment)="props">
          <BFormInput
            v-if="checkItemId(props.item)"
            v-model="formData.comment"
          />
          <span v-else>{{ props.item.comment }}</span>
        </template>
        <template #cell(actions)="props">
          <div v-if="props.item.id !== formData.id">
            <BButton
              class="p-50 mr-1"
              variant="warning"
              @click="editRow(props.item)"
            >
              <feather-icon icon="EditIcon" />
            </BButton>
            <BButton
              class="p-50"
              variant="danger"
              @click="deleteData"
            >
              <feather-icon icon="XIcon" />
            </BButton>
          </div>
        </template>
      </BTable>
    </div>

    <div class="d-flex justify-content-center">
      <BButton
        v-if="!formData.active"
        class="mr-2"
        variant="primary"
        @click="addNewData"
      >
        <feather-icon
            icon="PlusIcon"
        />
        Добавить имущество
      </BButton>
      <div v-else>
        <BButton
          class="mr-2"
          variant="warning"
          @click="clearData"
        >
          Сохранить
        </BButton>
        <BButton
          class="border"
          variant="light"
          @click="clearData"
        >
          Отменить
        </BButton>
      </div>
    </div>

    <TheCommentsModal ref="comments" />
  </BModal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BTable,
  BButton,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import VcDatePicker from '@/components/VcDatePicker.vue'
import { validate } from 'vee-validate'
import TheCommentsModal from './TheCommentsModal.vue'

const defData = {
  date: 'date',
  date_doc: 'date_doc',
  type: 'type',
  files: [],
  info: 'info',
  comment: 'comment',
  actions: 'actions',
}

export default {
  name: 'TheDebtorIncomeModal',
  components: {
    BModal,
    BFormInput,

    BFormCheckbox,
    BFormFile,
    BTable,
    BButton,
    VSelect,
    VcDatePicker,
    TheCommentsModal,
  },
  data() {
    return {
      formData: {
        active: false,
      },
      fields: [
        {
          label: 'Дата создания',
          key: 'date',
          sortable: true,
        },
        {
          label: 'Дата документа',
          key: 'date_doc',
        },
        {
          label: 'Вид',
          key: 'type',
        },
        {
          label: 'Файлы',
          key: 'files',
        },
        {
          label: 'Полезная информация',
          key: 'info',
        },
        {
          label: 'Комментарий',
          key: 'comment',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      propertiesList: [
        {
          id: 1,
          date: 'date',
          date_doc: 'date_doc',
          type: 'type',
          files: [],
          info: 'info',
          comment: 'comment',
          actions: 'actions',
        },
        {
          id: 2,
          date: 'date',
          date_doc: 'date_doc',
          type: 'type',
          files: [],
          info: 'info',
          comment: 'comment',
          actions: 'actions',
        },
      ],

      types: [
        {
          title: 'Наследство',
          value: 'legacy',
        },
      ],
      statuses: [
        {
          title: 'Продано',
          value: 'sold',
        },
        {
          title: 'Приобретено',
          value: 'purchased',
        },
      ],
      reg_organs: [
        {
          title: 'Расрочка',
          value: 'loan',
        },
      ],
    }
  },

  mounted() {
    this.propertiesList.forEach(item => {
      item.edit = false
    })
  },

  methods: {
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },
    openCommentModal() {
      this.$refs.comments.open()
    },
    editRow(item) {
      this.clearEptyObj()
      this.formData = {
        active: true,
        ...item,
      }
    },

    checkItemId(item) {
      return item.id === this.formData.id
    },

    deleteData() {
      this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить эту запись?',
        {
          title: 'Удалить',
          okVariant: 'danger',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отменить',
          hideHeaderClose: false,
          centered: true,
        },
      )
    },
    addNewData() {
      this.formData = {
        id: 0,
        active: true,
        ...defData,
      }
      this.propertiesList.push({
        id: 0,
        active: true,
        ...defData,
      })
    },
    clearEptyObj() {
      this.propertiesList = this.propertiesList.filter(e => parseInt(e.id))
    },
    clearData() {
      this.clearEptyObj()
      this.formData = {
        active: false,
        ...defData,
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.select-w-z-index {
  min-width: 250px;
  z-index: 9999;
}
</style>
