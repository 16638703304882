<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <BButton
        variant="secondary"
        class="pl-1"
        @click="$router.go(-1)"
      >
        <ReplyIcon
          class="mr-50"
        />
        <span>
          Назад
        </span>
      </BButton>

      <h4
        v-if="debtListId.BORROWER.TIME_ZONE || debtListId.BORROWER.TIME_ZONE_UTC !== null"
        class="mb-0"
      >
        Timezone: {{ debtListId.BORROWER.TIME_ZONE }}
        UTC: {{ formattedTimeZoneOffset }}
      </h4>
    </div>

    <b-card class="text-center mt-1">
      <h3 class="mb-0">
        <span v-if="debtListId.BORROWER.FULL_NAME"> {{ debtListId.BORROWER.FULL_NAME }},</span>
        <span v-if="debtListId.BASIS.DEBT"> {{ $_moneyFormatter(debtListId.BASIS.DEBT) }}, </span>
        <span v-if="debtListId.CLAIMER_COMPANY_NAME"> {{ debtListId.CLAIMER_COMPANY_NAME }}</span>
      </h3>
    </b-card>

    <BCard class="mt-1">
      <BRow>
        <BCol md="4">
          <p class="mb-50">
            Ответственный сотрудник: {{ debtListId.MANAGER }}
          </p>
          <p class="mb-50">
            Срок работы с делом: {{ debtListId.BASIS.DEBT_PERIOD }} дней
          </p>
          <p class="mb-50">Последний платёж: Платёж {{ debtListId.BASIS.PAY_DATE }}
            на сумму
            {{ $_moneyFormatter(debtListId.BASIS.PAY_AMOUNT) }}
          </p>
          <p class="mb-50">
            Последнее действие:
            <span>{{ debtListId.LAST_ACTION_DATE }} {{ debtListId.LAST_ACTION_NAME }}</span>
          </p>
        </BCol>
        <BCol
          md="4"
          class="position-relative"
        >
          <div
            class="position-absolute"
            style="right: calc(15% + 5px)"
          >
            <BButton
              style="padding: 3px"
              class="rounded-circle mr-50"
            >
              <feather-icon
                icon="EditIcon"
              />
            </BButton>
          </div>
          <p class="mb-50">
            Дата продажи: {{ debtListId.BASIS.SALE_DATE }}
          </p>
          <p class="mb-50">
            Приоритетное дело: {{ debtListId.BASIS.PRIORITY }}
          </p>
          <p class="mb-50">
            Стадия: {{ DO_STAGE_TEXT }}
          </p>
          <p class="mb-50">
            Принадлежность: {{ belongingOptions[debtListId.BASIS.BELONGING] }}
          </p>
        </BCol>
        <BCol md="4">
          <BRow>
            <BCol
              v-for="(card, index) in skDebtTag.results"
              :key="index"
              md="6"
            >
              <div class="d-flex justify-content-between w-100">
                <label
                  class="p-style"
                  :for="'checkbox_' + index"
                >
                  {{ card.name }}
                </label>
                <BFormCheckbox
                  :id="'checkbox_' + index"
                  :checked="TAGS.includes(card.id)"
                  @change="checkedTag($event, card)"
                />
              </div>
            </BCol>
          </BRow>
        </BCol>
      </BRow>

      <BRow>
        <BCol
          md="4"
        >
          <BButton
            variant="info"
            @click="openPaymentHistory"
          >
            Посмотреть историю платежей
          </BButton>
        </BCol>
        <BCol
          md="4"
        >
          <BButton
            variant="info"
            @click="openCaseChangeHistory"
          >
            Посмотреть историю изменений дела
          </BButton>
        </BCol>
        <BCol
          class="text-right"
          md="4"
        >
          <b-button
            variant="info"
            @click="updateTags"
          >
            Сохранить теги
          </b-button>
        </BCol>
      </BRow>

      <ThePaymentHistory
        ref="paymentHistory"
      />

      <!--//*********************** history modal *********************//-->
      <TheCaseChangeHistory
        ref="caseChangeHistory"
        :load-his="loadHis"
        @refresh="refresh"
      />
      <!--//*********************** history modal *********************//-->
    </BCard>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BFormCheckbox, BCard,
} from 'bootstrap-vue'
import ReplyIcon from 'vue-material-design-icons/Reply.vue'
import { mapActions, mapState } from 'vuex'
import ThePaymentHistory from './ThePaymentHistory.vue'
import TheCaseChangeHistory from './TheCaseChangeHistory.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BFormCheckbox,
    BCard,
    ReplyIcon,
    ThePaymentHistory,
    TheCaseChangeHistory,
  },
  data() {
    return {
      loadHis: false,
      TAGS: [],
      checkedVal: false,
      belongingOptions: {
        bought: 'Купленный',
        agency: 'Агентский',
      },
      cardChecks: [
        {
          title: 'VIP должник',
          value: false,
        },
        {
          title: 'Наличие поручителей',
          value: false,
        },
        {
          title: 'Место работы: бюджет',
          value: false,
        },
        {
          title: 'Фиктивная справка',
          value: false,
        },
        {
          title: 'Отсутствие платежей',
          value: false,
        },
        {
          title: 'Пенсионеры',
          value: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['skDebtTag', 'debtListId']),

    DO_STAGE_TEXT() {
      let text = ''
      // eslint-disable-next-line default-case
      switch (this.debtListId.BASIS.DO_STAGE) {
        case 'voluntary_payment':
          text = 'Добровольная оплата'
          break
        case 'claim':
          text = 'Претензия'
          break
        case 'judical_work':
          text = 'Судебная работа'
          break
        case 'executive_document':
          text = 'Исполнительный документ'
          break
        case 'executive_work':
          text = 'Исполнительное производство'
          break
        case 'stopped':
          text = 'Приостановлено'
          break
        case 'paid':
          text = 'Погашен'
          break
      }
      return text
    },

    docTitle() {
      return `${this.debtListId.BORROWER.FULL_NAME} ${this.debtListId.BASIS.DEBT} ${this.debtListId.CLAIMER_COMPANY_NAME}`
    },

    formattedTimeZoneOffset() {
      const offset = this.debtListId.BORROWER.TIME_ZONE_UTC
      const sign = offset >= 0 ? '+' : '-'
      const hours = Math.abs(Math.floor(offset))
      const minutes = Math.abs((offset % 1) * 60)

      const formattedHours = hours.toString().padStart(2, '0')
      const formattedMinutes = minutes.toString().padStart(2, '0')

      return `${sign}${formattedHours}:${formattedMinutes}`
    },
  },
  mounted() {
    this.FETCH_SK_DEBT_ID({ id: this.$route.params.id })
      .then(() => {
        this.TAGS = this.debtListId.TAG
        document.title = this.docTitle
      })
    this.FETCH_SK_DEBT_TAG()
  },
  methods: {
    ...mapActions('cases', [
      'FETCH_SK_DEBT_HISTORY',
      'FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL',
      'FETCH_SK_DEBT_TAG',
      'UPDATE_SK_DEBT__TAG',
      'FETCH_SK_DEBT_ID',
    ]),

    openPaymentHistory() {
      this.$refs.paymentHistory.open()
    },

    openCaseChangeHistory() {
      this.$refs.caseChangeHistory.open()
      this.refresh({ id: this.$route.params.id })
    },

    paymentRefresh(data) {
      this.FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ id: this.$route.params.id, ...data })
    },

    async refresh(data) {
      try {
        this.loadHis = true
        await this.FETCH_SK_DEBT_HISTORY({ id: this.$route.params.id, ...data })
      } catch (error) {
        await this.$_errorToast()
      } finally {
        this.loadHis = false
      }
    },

    updateTags() {
      this.UPDATE_SK_DEBT__TAG({
        id: this.$route.params.id,
        TAG: this.TAGS,
      })
        .then(() => {
          this.FETCH_SK_DEBT_TAG()
          this.$_okToast()
        })
    },

    checkedTag(val, card) {
      let replaceIndex
      if (val) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.TAGS) {
          if (card.id > key) {
            replaceIndex = this.TAGS.indexOf(key) + 1
          }
        }

        this.TAGS.splice(replaceIndex, 0, card.id)
      } else {
        const deleteItemIndex = this.TAGS.indexOf(card.id)
        this.TAGS.splice(deleteItemIndex, 1)
      }
    },
  },
}
</script>

<style>
.p-style {
  line-height: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
</style>
