<template>
  <BCard>
    <BTabs>
      <BTab
        v-for="(tab, index) in tabList"
        :key="index"
        variant="info"
        :title="tab.title"
        active-tab-class="text-success"
        :active="findActive(tab.tabTitle)"
        lazy
        @click="changeTab(tab.tabTitle)"
      >
        <template #title>
          {{ tab.title }}
        </template>
        <component
          :is="tab.component"
        />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import StatementDebtor from '@/modules/statementDebtor/views/Home.vue'
import ClaimsLitigation from '@/modules/claimsLitigation/views/Home.vue'
import SSP from '@/modules/ssp/views/Home.vue'
import caseCardActions from '@/modules/caseCardActions/views/Home.vue'

export default {
  name: 'TheBody',
  components: {
    BCard,
    BTabs,
    BTab,
    StatementDebtor,
    ClaimsLitigation,
    // SSP,
    // caseCardActions,
  },
  data() {
    return {
      tabList: [
        {
          title: 'Выписка по должнику',
          component: StatementDebtor,
          active: true,
          tabTitle: 'tab1',
        },
        {
          title: 'Претензионно-судебная работа',
          component: ClaimsLitigation,
          tabTitle: 'tab2',
        },
        {
          title: 'ССП', // служба судебных приставов
          component: SSP,
          tabTitle: 'tab3',
        },
        {
          title: 'Действия',
          component: caseCardActions,
          tabTitle: 'tab4',
        },
      ],
    }
  },
  computed: {
    tabActive: {
      get() { return this.$store.state.cases.tabActive },
      set(value) { this.$store.commit('cases/SET_ACTIVE_TAB', value) },
    },

    dosser: {
      get() { return this.$store.state.cases.dosserId },
      set(value) { this.$store.commit('cases/SET_DOSSER_ID', value) },
    },
  },
  methods: {
    changeTab(tabTitle) {
      this.tabActive = tabTitle
      this.dosser = {}
    },
    findActive(tabTitle) {
      if (this.tabActive === 'tab1' && tabTitle === 'tab1') {
        return true
      } if (this.tabActive === 'tab2' && tabTitle === 'tab2') {
        return true
      } if (this.tabActive === 'tab3' && tabTitle === 'tab3') {
        return true
      } if (this.tabActive === 'tab4' && tabTitle === 'tab4') {
        return true
      }
      return false
    },
  },
}
</script>
