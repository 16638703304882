<template>
  <div>
    <BRow>
      <BCol md="6">
        <TheDebtor />
      </BCol>
      <BCol md="6">
        <TheLoan />
        <TheDebtObligation />
        <TheSuccession />
        <TheBankrupt />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import TheDebtor from '../components/TheDebtor.vue'
import TheLoan from '../components/TheLoan.vue'
import TheDebtObligation from '../components/TheDebtObligation.vue'
import TheSuccession from '../components/TheSuccession.vue'
import TheBankrupt from '../components/TheBankrupt.vue'

export default {
  components: {
    BRow,
    BCol,
    TheDebtor,
    TheLoan,
    TheDebtObligation,
    TheSuccession,
    TheBankrupt,
  },
  mounted() {
    // console.clear()
  },
}
</script>

<style>
</style>
