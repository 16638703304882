<template>
  <div>
    <div class="rounded border w-100 p-1">
      <BButton
        v-for="(item, index) in skDebtCcp.results"
        :key="index"
        class="border mr-1"
        variant="light"
        :class="{'activeList' : current === item.id}"
        @click="filteredDate(item, item.id)"
      >
        ИД от {{ item.EXECUTIVE_DATE }}
      </BButton>
    </div>
    <BRow>
      <BCol md="6">
        <TheExecutiveDocument />
      </BCol>
      <BCol md="6">
        <TheAmounts />
        <TheEnforcementProceedings
          v-if="skDebtCcpButtonFilter && skDebtCcpButtonFilter.id && current"
          :current="current"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import TheExecutiveDocument from '../components/TheExecutiveDocument.vue'
import TheAmounts from '../components/TheAmounts.vue'
import TheEnforcementProceedings from '../components/TheEnforcementProceedings.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    TheExecutiveDocument,
    TheAmounts,
    TheEnforcementProceedings,
  },

  data() {
    return {
      current: null,
    }
  },
  computed: {
    ...mapState('cases', ['skDebtCcp', 'skDebtCcpButtonFilter', 'dosserId']),
  },
  async mounted() {
    try {
      await this.FETCH_SK_DEBT_CCP({ id: this.$route.params.id })
      if (this.skDebtCcp.results.length) {
        if (this.dosserId.id) {
          await this.filteredDate({ id: this.dosserId.id }, this.dosserId.id)
        } else {
          await this.filteredDate({ id: this.skDebtCcp.results?.[0].id }, this.skDebtCcp.results?.[0].id)
        }
        await this.FETCH_SK_DEBT_EXECUTIVE_DOCUMENT({ id: this.$route.params.id })
      } else {
        this.SET_SK_DEBT_CCP_BUTTON_FILTER({ results: [] })
      }
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('cases', [
      'FETCH_SK_DEBT_CCP',
      'FETCH_SK_DEBT_CCP_FILTERED_DATE',
      'FETCH_SK_DEBT_EXECUTIVE_DOCUMENT',
    ]),
    ...mapMutations('cases', ['SET_SK_DEBT_CCP_BUTTON_FILTER']),

    async filteredDate(item, id) {
      try {
        this.current = id
        await this.FETCH_SK_DEBT_CCP_FILTERED_DATE({ id: item.id })
      } catch (error) {
        await this.$_errorToast()
      }
    },
  },
}
</script>

<style>
.activeList {
  background: #2639BB !important;
  color: #fff !important;
}

.activeList svg:nth-child(1) {
  color: #fff;
}
</style>
