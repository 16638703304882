<template>
  <div>
    <div class="w-100 bg-light p-1 mb-2 rounded d-flex justify-content-between">
      <p class="mb-0">
        Исполнительный документ
      </p>
      <BButton
        v-if="skDebtCcpButtonFilter.CONTENT_TYPE"
        style="padding: 3px"
        :variant="!editItem ? 'warning' : 'light'"
        class="rounded-circle mr-50"
        @click="editItem = !editItem"
      >
        <feather-icon icon="EditIcon" />
      </BButton>
    </div>

    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav">
          <p>Тип:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.TYPE"
            class="w-100"
            label="title"
            :reduce="p => p.value"
            :options="TYPE_CCP"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ type2 }}
          </p>
        </div>
        <div class="filds__nav">
          <p>Дата:</p>
          <p>
            <span>{{ skDebtCcpButtonFilter.EXECUTIVE_DATE }}</span>
          </p>
        </div>

        <div class="filds__nav">
          <p>Судебный акт:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.ADJUDGEMENT_DOCUMENT_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            <span>{{ skDebtCcpButtonFilter.ADJUDGEMENT_DOCUMENT_DATE }}</span>
          </p>
        </div>

        <div class="filds__nav">
          <p>Номер документа:</p>
          <b-form-input
            v-model="skDebtCcpButtonFilter.EXECUTIVE_NUMBER"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Срок предъявления:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.PRESENT_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            <span
              v-if="skDebtCcpButtonFilter.PRESENT_DATE"
            >{{ skDebtCcpButtonFilter.PRESENT_DATE }}</span>
          </p>
        </div>

        <div class="filds__nav">
          <p>Место нахождения:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.EXECUTIVE_STATUS"
            class="w-100"
            label="title"
            :reduce="p => p.value"
            :options="EXECUTIVE"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <div
            v-else
            class="w-100"
          >
            {{ getLocation }}
          </div>
        </div>
        <div class="filds__nav">
          <p>На кого:</p>
          <v-select
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.CONTACT"
            class="w-100"
            :get-option-label="(el) => el.FULL_NAME + ' ' + el.TYPE"
            :reduce="p => p.FULL_NAME"
            :options="skDebtCcpExecutiveDoc.CONTACT"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ skDebtCcpButtonFilter.CONTACT }}
          </p>
        </div>

        <div class="filds__nav">
          <p>Документы:</p>
          <p
            v-if="skDebtCcpButtonFilter.CONTENT_TYPE"
            class="a__click"
            @click="openDocModal"
          >
            Документы
          </p>
        </div>

        <div class="filds__nav">
          <p>Отложить до:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.DELAY_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            <span v-if="skDebtCcpButtonFilter.DELAY_DATE">{{ skDebtCcpButtonFilter.DELAY_DATE }}</span>
          </p>
        </div>

        <div class="filds__nav">
          <p>Дата возврата ИД:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="skDebtCcpButtonFilter.RETURN_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            <span v-if="skDebtCcpButtonFilter.RETURN_DATE">
              {{ skDebtCcpButtonFilter.RETURN_DATE }}
            </span>
          </p>
        </div>

        <div class="filds__nav">
          <p>Комментарии:</p>
          <p
            v-if="skDebtCcpButtonFilter.CONTENT_TYPE"
            class="a__click"
            @click="openModal('comments')"
          >

            Комментариев
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="editItem = true"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateDocCcp"
      >
        Сохранить
      </b-button>
    </div>

    <!--//document modal-->
    <theDocModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
    <!--//document modal end-->

    <!--//comment modal-->
    <TheCommentsModal
      ref="comments"
      :comment="comments"
      :comment-message="commentDebt.results"
      @createComment="createComment"
      @refresh="refresh"
      @deleteComment="deleteComment"
    />
    <!--//comment modal end-->
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import TheCommentsModal from '@components/modules/TheCommentsModal.vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import useJwt from '@/auth/jwt/useJwt'
import theDocModal from '@/components/modalDocDebts/theDocModal.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  components: {
    BButton,
    TheCommentsModal,
    theDocModal,
    BFormInput,
    vSelect,
    flatPickr,
  },
  mixins: [areYouSure],
  data() {
    return {
      type: 'executer',
      editItem: true,
      comments: {
        message: '',
      },
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    ...mapState('cases', [
      'skDebtCcpButtonFilter',
      'commentDebt',
      'debtDocument',
      'EXECUTIVE',
      'TYPE_CCP',
      'skDebtCcpExecutiveDoc',
    ]),

    getLocation() {
      let text = ''
      // eslint-disable-next-line default-case
      switch (this.skDebtCcpButtonFilter.EXECUTIVE_STATUS) {
        case 'executive_list':
          text = 'Исполнительный лист'
          break
        case 'court_order':
          text = 'Судебный приказ'
          break
        case 'executive_inscription':
          text = 'Исполнительная надпись'
          break
        case 'claimer':
          text = 'У взыскателя'
          break
        case 'agent':
          text = 'У агента'
          break
        case 'pristav':
          text = 'У приставов'
          break
        case 'lost':
          text = 'Утерян'
          break
        case 'stopped':
          text = 'ИП приостановлено'
          break
        case 'payed':
          text = 'Оплачено'
      }
      return text
    },

    type2() {
      let typeText = ''
      // eslint-disable-next-line default-case
      switch (this.skDebtCcpButtonFilter.TYPE) {
        case 'executive_list':
          typeText = 'Исполнительный лист'
          break
        case 'court_order':
          typeText = 'Судебный приказ'
          break
        case 'executive_inscription':
          typeText = 'Исполнительная надпись'
          break
      }
      return typeText
    },

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },
  },
  methods: {
    ...mapActions('cases', [
      'UPDATE_SK_DEBT_CCP_SUM',
      'CREATE_COMMENT',
      'FETCH_COMMENT_DEBT',
      'DELETE_COMMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
      'DELETE_DEBT_DOCUMENT',
      'FETCH_DEBT_DOCUMENT',
    ]),
    openModal(modal = '') {
      if (this.skDebtCcpButtonFilter.CONTENT_TYPE) {
        const enm = ['comments']
        if (enm.includes(modal)) {
          this.$refs[modal].open()
        } else {
          console.error('Нет такого модального окна ', JSON.stringify(modal))
        }
      }
    },

    updateDocCcp() {
      delete this.skDebtCcpButtonFilter.EXECUTIVE_DATE
      delete this.skDebtCcpButtonFilter.modified_date
      this.UPDATE_SK_DEBT_CCP_SUM({
        id: this.skDebtCcpButtonFilter.id,
        CONTACT: this.skDebtCcpButtonFilter.CONTACT,
        ...this.skDebtCcpButtonFilter,
      })
        .then(() => {
          this.editItem = true
          this.$_okToast()
        })
    },

    // ******************* comments methods *********************** //
    createComment() {
      this.CREATE_COMMENT({
        author: this.getUserId,
        content_type: this.skDebtCcpButtonFilter.CONTENT_TYPE,
        object_id: this.skDebtCcpButtonFilter.id,
        ...this.comments,
      })
        .then(() => {
          this.refresh()
          this.$_okToast()
          this.comments.message = ''
        })
    },

    refresh() {
      this.FETCH_COMMENT_DEBT({
        content_type: this.skDebtCcpButtonFilter.CONTENT_TYPE,
        object_id: this.skDebtCcpButtonFilter.id,
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
          }
        })
    },
    // ***************** comments methods end ********************* //

    // ******************** document **************************//
    openDocModal() {
      if (this.skDebtCcpButtonFilter.CONTENT_TYPE) {
        this.$bvModal.show('modalDoc')
        this.docRefresh()
      }
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }

      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.skDebtCcpButtonFilter.CONTENT_TYPE)
      formData.append('object_id', this.skDebtCcpButtonFilter.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.hideModal()
          this.$bvModal.hide('addEditDoc')
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.skDebtCcpButtonFilter.CONTENT_TYPE)
      formData.append('object_id', this.skDebtCcpButtonFilter.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.tags = []
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.skDebtCcpButtonFilter.CONTENT_TYPE,
        object_id: this.skDebtCcpButtonFilter.id,
        ...data,
      })
    },
    // ******************** document end **************************//
  },
}
</script>
