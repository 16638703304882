<template>
  <div>
    <BModal
      id="modalClaims"
      size="xl"
      centered
      title="Претензии"
      ok-variant="info"
      ok-title="Да"
      cancel-title="Отмена"
      hide-footer
      responsive
      no-close-on-backdrop
    >
      <BTable
        class="my-2"
        :items="skDebtJudicalWorkClaimsModal.results"
        :fields="fields"
        show-empty
        empty-text="Нет данных"
      >
        <template #cell(created_date)="value">
          {{ value.item.created_date }}
        </template>
        <template #cell(documents)="props">
          <p
            class="a__click"
            @click="openDocumentsModal(props.item)"
          >
            Документы
          </p>
        </template>
        <template #cell(actions)="props">
          <div>
            <BButton
              class="p-50 mr-1"
              variant="warning"
              @click="editRow(props.item)"
            >
              <feather-icon icon="EditIcon" />
            </BButton>
            <BButton
              class="p-50"
              variant="danger"
              @click="deleteData(props.item.id)"
            >
              <feather-icon icon="XIcon" />
            </BButton>
          </div>
        </template>
      </BTable>
      <BCPagination
        :go-to-page="false"
        :count="skDebtJudicalWorkClaimsModal.count"
        @input="onRefresh"
      />
    </BModal>

    <claimsDocModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />

    <claimsEditModal
      :edit-claims="editClaims"
      @refresh="$emit('claimsRefresh')"
    />
  </div>
</template>

<script>
import {
  BModal,
  BTable,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import useJwt from '@/auth/jwt/useJwt'
import claimsEditModal from '@/modules/claimsLitigation/claimsModal/claimsEditModal.vue'
import BCPagination from '@/components/BCPagination.vue'
import claimsDocModal from '@/modules/claimsLitigation/components/claimsDocModal.vue'

export default {
  name: 'TheDebtorIncomeModal',
  components: {
    BModal,
    BTable,
    BButton,
    claimsDocModal,
    claimsEditModal,
    BCPagination,
  },
  mixins: [areYouSure],
  data() {
    return {
      type: 'Претензии',
      contentTypeClaim: null,
      objectIdClaim: null,
      fields: [
        {
          label: 'Дата',
          key: 'created_date',
          sortable: true,
        },
        {
          label: 'Дата отправки',
          key: 'JUD_DATE_SENT',
          sortable: true,
        },
        {
          label: 'Дата получения',
          key: 'RECEIPT_DATE',
        },
        {
          label: 'Документы',
          key: 'documents',
        },
        {
          label: 'На кого',
          key: 'CONTACT',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      editClaims: {},
    }
  },
  computed: {
    ...mapState('cases', [
      'skDebtJudicalWorkClaimsModal',
      'debtDocument',
    ]),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },
  },

  methods: {
    ...mapActions('cases', [
      'DELETE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE',
      'FETCH_DEBT_DOCUMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
      'DELETE_DEBT_DOCUMENT',
    ]),

    onRefresh(data) {
      this.$emit('claimsRefresh', data)
    },

    editRow(item) {
      this.editClaims = item
      this.$bvModal.show('modalClaimsEditModalId')
    },

    deleteData(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE(id)
              .then(() => {
                this.$emit('claimsRefresh')
                this.$_okToast()
              })
          }
        })
    },

    // *********************** document *****************************//
    openDocumentsModal(item) {
      this.$bvModal.show('claimsDocModal')
      this.contentTypeClaim = item.CONTENT_TYPE
      this.objectIdClaim = item.id
      this.FETCH_DEBT_DOCUMENT({
        content_type: item.CONTENT_TYPE,
        object_id: item.id,
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              }).catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }

      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.contentTypeClaim)
      formData.append('object_id', this.objectIdClaim)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEdiClaimstDoc2')
          this.hideModal()
        }).catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.contentTypeClaim)
      formData.append('object_id', this.objectIdClaim)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEdiClaimstDoc2')
          this.$_okToast()
          this.hideModal()
        }).catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.file_title = null
        this.formData.tags = []
      })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.contentTypeClaim,
        object_id: this.objectIdClaim,
        ...data,
      })
    },
    // ******************** document end **************************//
  },
}
</script>
<style lang="scss">
</style>
