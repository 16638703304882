<template>
  <BTabs>
    <BTab
      v-for="(tab, index) in tabList"
      :key="index"
      class="position-relative"
      variant="info"
      :title="tab.title"
      active-tab-class="text-success"
      :active="tab.active"
      lazy
    >
      <template #title>
        <span>{{ tab.title }}</span>
      </template>
      <div
        v-if="tab.button"
        class="flex-grow-1 d-flex justify-content-end"
        style="position: absolute; top: -40px; right: 0"
      >
        <BButton
          variant="primary"
          @click="openHeader"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить действие
        </BButton>

        <BButton
          class="d-flex align-items-center ml-1"
          variant="success"
          @click="exportExcel"
        >
          Скачать в Excel
          <b-spinner
            v-if="loading2"
            style="width: 1.5rem; height: 1.5rem"
            class="ml-50"
          />
        </BButton>
      </div>
      <br>
      <TheHeader
        v-if="index === 0"
        ref="head"
      />
      <TheBody
        :datas="tab.filter"
        :key-s="tab.keyS"
        :edit-item-action="editItemAction1"
        @openEditModal="openEditModal"
      />
    </BTab>

    <actionModal
      :edit-item-action1="editItemAction1"
    />
  </BTabs>
</template>

<script>
import {
  BButton,
  BTabs,
  BTab,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import TheHeader from '../components/TheHeader.vue'
import TheBody from '../components/TheBody.vue'
import actionModal from '../components/editModalAction/modal.vue'

export default {
  components: {
    BButton,
    BTabs,
    BTab,
    BSpinner,
    TheHeader,
    TheBody,
    actionModal,
  },

  data() {
    return {
      loading: false,
      loading2: false,
      editItemAction1: {},
      tabList: [
        {
          title: 'Новые',
          active: true,
          button: true,
          filter: 'NO_STATUS',
          keyS: 1,
        },
        {
          title: 'Завершённые',
          button: false,
          filter: 'COMPLETE',
          keyS: 2,
        },
        {
          title: 'Удалённые',
          button: false,
          filter: 'DELETE',
          keyS: 3,
        },
      ],
    }
  },
  computed: {
    ...mapState('actions', ['skDebtAction']),
  },
  methods: {
    ...mapActions('users', ['FETCH_ALL_USERS']),
    ...mapActions('actions', ['FETCH_DEBT_ACTION_EXCEL']),

    async exportExcel() {
      try {
        this.loading2 = true
        await this.FETCH_DEBT_ACTION_EXCEL({ current_step: '1' })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'file.xls')
            document.body.appendChild(link)
            link.click()
            this.loading2 = false
          })
      } catch (error) {
        await this.$_errorToast()
      }
    },

    openHeader() {
      if (this.$refs.head.length) {
        this.$refs.head[0].open()
        this.FETCH_ALL_USERS({ page_size: 1000 })
      }
    },

    openEditModal(item) {
      this.editItemAction1 = item
      this.$nextTick(() => {
        this.$bvModal.show('modal-acction')
      })
    },
  },
}
</script>
