<template>
  <div>
    <BModal
      id="judicalActId"
      size="md"
      centered
      title="Судебный акт"
      ok-variant="primary"
      ok-title="Сохранить"
      cancel-title="Отменить"
      cancel-variant="outline-secondary"
      responsive
      no-close-on-backdrop
      @show="openShowModal"
      @ok="updateAdjudment"
    >
      <div class="text-right w-100">
        <b-button
          class="rounded-circle p-50"
          size="sm"
          variant="secondary"
          @click="editItem = !editItem"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </div>
      <div class="w-100 m-auto text-center">
        <h2 class="mb-2">
          Основное
        </h2>

        <div class=" d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="act.DOCUMENT_DATE"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ act.DOCUMENT_DATE }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Вид:
            </p>
          </div>
          <v-select
            v-if="!editItem"
            v-model="act.SATISFACTION"
            class="w-50"
            label="title"
            :reduce="staisfaction => staisfaction.value"
            :options="SATISFACTION"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ getSatisfaction }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата вступления в силу:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="act.JUD_DATE_1"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ act.JUD_DATE_1 }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Оспаривать:
            </p>
          </div>
          <v-select
            v-if="!editItem"
            v-model="act.APPEAL"
            class="w-50"
            label="title"
            :reduce="appeal => appeal.value"
            :options="APPEAL"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ act.APPEAL }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Длящиеся требования:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="act.LONG_REQUIREMENTS"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ act.LONG_REQUIREMENTS }}
          </p>
        </div>

        <div class="d-flex w-100 my-1">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p
              class="w-100"
            >
              Документы
            </p>
          </div>
          <p
            class="a__click w-auto"
            @click="openDocModal"
          >
            Документы
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Комментарии
            </p>
          </div>
          <p
            class="a__click w-auto"
            @click="openModal('comments')"
          >
            Комментариев
          </p>
        </div>

        <h2 class="mb-2">
          Суммы
        </h2>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма задолженности
            </p>
          </div>
          <b-form-input
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма ОД
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_OD_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма процентов
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_PERCENT_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма ЧВ
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_XZ_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма пени ЧВ
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_PENI_XZ_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Суммы пени
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_PENI_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Суммы ЮУ
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_UY_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Суммы ГП
            </p>
          </div>
          <b-form-input
            v-model="act.JUD_GP_S"
            :plaintext="editItem"
            class="w-50"
          />
        </div>
      </div>
    </BModal>

    <theDocClaimsModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />

    <TheCommentsModal
      ref="comments"
      :comment="comments"
      :comment-message="commentDebt.results"
      @createComment="createComment"
      @refresh="refresh"
      @deleteComment="deleteComment"
    />
  </div>
</template>
<script>
import {
  BModal,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import VcDatePicker from '@/components/VcDatePicker.vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import theDocClaimsModal from '@/modules/claimsLitigation/claimsModal/doc/theDocClaimsModal.vue'
import TheCommentsModal from '@/components/modules/TheCommentsModal.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'JudicalActModal',
  components: {
    BModal,
    vSelect,
    BFormInput,
    BButton,
    VcDatePicker,
    theDocClaimsModal,
    TheCommentsModal,
  },
  mixins: [areYouSure],
  data() {
    return {
      type: 'act',
      editItem: true,
      act: {
        DOCUMENT_DATE: null,
        SATISFACTION: null,
        JUD_GP_S: null,
        JUD_UY_S: null,
        JUD_PENI_S: null,
        JUD_PENI_XZ_S: null,
        JUD_XZ_S: null,
        JUD_PERCENT_S: null,
        JUD_OD_S: null,
        JUD_SUM: null,
        LONG_REQUIREMENTS: null,
        APPEAL: null,
        JUD_DATE_1: null,
      },
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      comments: {
        message: '',
      },
    }
  },
  computed: {
    ...mapState('cases', [
      'SkDebtAdjudgement',
      'SATISFACTION',
      'APPEAL',
      'debtDocument',
      'commentDebt',
    ]),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },

    getSatisfaction() {
      let text = ''
      switch (this.act.SATISFACTION) {
        case 'full':
          text = 'Полное'
          break
        case 'part':
          text = 'Частичное'
          break
        case 'no':
          text = 'Не удовлетворено'
          break
      }
      return text
    },
  },
  methods: {
    ...mapActions('cases', [
      'UPDATE_SK_DEBT_ADJUDGEMENT_MODAL',
      'DELETE_DEBT_DOCUMENT',
      'FETCH_DEBT_DOCUMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
      'CREATE_COMMENT',
      'FETCH_COMMENT_DEBT',
      'DELETE_COMMENT',
    ]),

    openShowModal() {
      this.act = this.SkDebtAdjudgement
    },

    updateAdjudment() {
      this.UPDATE_SK_DEBT_ADJUDGEMENT_MODAL({
        id: this.SkDebtAdjudgement.id,
        ...this.act,
      })
        .then(() => {
          this.$emit('refresh')
          this.$_okToast()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    // ******************** document **************************//
    openDocModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modalClaimsDoc')
      })
      this.docRefresh()
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }
      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.SkDebtAdjudgement.CONTENT_TYPE)
      formData.append('object_id', this.SkDebtAdjudgement.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEditDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        // formData.append('file', this.formData?.file)
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.SkDebtAdjudgement.CONTENT_TYPE)
      formData.append('object_id', this.SkDebtAdjudgement.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.file_title = null
        this.formData.tags = []
        this.$bvModal.hide('addEdiClaimstDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.SkDebtAdjudgement.CONTENT_TYPE,
        object_id: this.SkDebtAdjudgement.id,
        ...data,
      })
    },
    // ******************** document end **************************//

    // ******************* comments methods *********************** //
    openModal(modal) {
      const enm = ['comments']
      if (enm.includes(modal)) {
        this.$refs[modal].open()
      } else {
        console.error('Нет такого модального окна ', JSON.stringify(modal))
      }
    },

    createComment() {
      this.CREATE_COMMENT({
        author: this.getUserId,
        content_type: this.SkDebtAdjudgement.CONTENT_TYPE,
        object_id: this.SkDebtAdjudgement.id,
        ...this.comments,
      })
        .then(() => {
          this.refresh()
          this.$_okToast()
          this.comments.message = ''
        })
    },

    refresh() {
      this.FETCH_COMMENT_DEBT({
        content_type: this.SkDebtAdjudgement.CONTENT_TYPE,
        object_id: this.SkDebtAdjudgement.id,
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
          }
        })
    },
    // ***************** comments methods end ********************* //
  },
}
</script>
<style>
p {
    margin-bottom: 0;
}
</style>
