<template>
  <div>
    <div>
      <div class="w-100 bg-light p-1 rounded d-flex justify-content-between">
        <p class="mb-0">
          Должник {{ debtListId.BORROWER.FULL_NAME }},
          {{ age }} лет
        </p>

        <BButton
          v-if="debtListId.BORROWER.id"
          style="padding: 3px"
          :variant="!editItem ? 'warning' : 'light'"
          class="rounded-circle mr-50"
          @click="editItem = !editItem"
        >
          <feather-icon icon="EditIcon" />
        </BButton>
      </div>

      <div
        v-if="debtListId.BORROWER"
        class="d-flex justify-content-center p-1"
      >
        <div class="fields px-1 w-75">
          <div class="filds__nav mb-2 text-center">
            <p class="ml-5">
              Найти контакт в CRM
            </p>
          </div>
          <div class="filds__nav">
            <p class="text-right mr-1">
              Пол. возраст:
            </p>
            <div class="w-100 d-flex align-items-center">
              <v-select
                v-if="!editItem"
                v-model="debtListId.BORROWER.GENDER"
                class="w-100"
                :reduce="(p) => p.value"
                label="title"
                :options="gender"
              >
                <template #no-options>
                  К сожалению, нет подходящих вариантов
                </template>
              </v-select>
              <p v-else>
                {{ debtListId.BORROWER.GENDER }}
              </p>
              <p :class="!editItem ? 'w-25 ml-1' : ''">
                {{ age }} лет
              </p>
            </div>
          </div>
          <div class="filds__nav">
            <p class="text-right mr-1">
              Дата рождения:
            </p>
            <flat-pickr
              v-if="!editItem"
              v-model="debtListId.BORROWER.BIRTH_DATE"
              class="form-control"
              :config="configdateTimePicker"
            />
            <!--            <VcDatePicker-->
            <!--              v-if="!editItem"-->
            <!--              v-model="debtListId.BORROWER.BIRTH_DATE"-->
            <!--              class="w-100"-->
            <!--              :masks='{ input:["YYYY-MM-DD"] }'-->
            <!--              :other-props="{ 'is-range': false }"-->
            <!--            />-->
            <p v-else>
              {{ debtListId.BORROWER.BIRTH_DATE }}
            </p>
          </div>
          <div class="filds__nav">
            <p class="w-50 text-right">
              Семейное положение:
            </p>
          </div>

          <div class="filds__nav">
            <p class="text-right mr-1">
              Доходы:
            </p>
            <p
              class="hover-text-underline"
              @click="openIncomeModal"
            >
              Все источники дохода
            </p>
          </div>

          <div class="filds__nav">
            <p class="text-right mr-1">
              Имущество:
            </p>
            <p
              class="hover-text-underline"
              @click="openPropertyModal"
            >
              ИД
            </p>
          </div>

          <div class="filds__nav">
            <p class="text-right mr-1">
              Документы:
            </p>
            <p
              class="hover-text-underline"
              @click="openDocModal"
            >
              Документы
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="!editItem"
        class="text-center mb-1"
      >
        <b-button
          variant="danger"
          @click="cancel"
        >
          Отменить
        </b-button>
        {{ "" }}
        <b-button
          variant="primary"
          @click="updateSkContact"
        >
          Сохранить
        </b-button>
      </div>
    </div>

    <!--/////collapse-->
    <b-card
      v-for="(item, index) in debtListId.GUARANTOR"
      :key="index"
      no-body
      class="mb-1"
    >
      <button
        v-b-toggle="'collapse' + item.id"
        class="vb__collapse w-100 p-1 rounded d-flex justify-content-between"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            icon="ChevronDownIcon"
            class="mr-1"
          />
          <p class="mb-0">
            Поручитель {{ item.FULL_NAME }},
            {{ item.BIRTH_DATE !== null ? guarantor(item.BIRTH_DATE) : '...' }} лет
          </p>
        </div>
      </button>
      <b-collapse
        :id="'collapse' + item.id"
        accordion="my-accordion"
      >
        <div class="fields px-1 w-75 mt-1">
          <div class="filds__nav mb-2 text-center">
            <p class="ml-5">
              Найти контакт в CRM
            </p>
          </div>
          <div class="filds__nav">
            <p class="text-right mr-1">
              Дата рождения:
            </p>
            <p>
              {{ item.BIRTH_DATE }}
            </p>
          </div>
          <div class="filds__nav">
            <p class="text-right mr-1">
              Адрес регистрации:
            </p>
            <p>
              {{ item.PERM_REG }}
            </p>
          </div>
          <div class="filds__nav">
            <p class="text-right mr-1">
              Адрес:
            </p>
            <p>
              {{ item.HOME_ADDRESS }}
            </p>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <!--/////collapse-->

    <TheDebtorIncomeModal
      ref="incomeModal"
      @workModal="workModal"
    />

    <TheDebtorPropertyModal
      ref="propertyModal"
      @propertyRefresh="propertyRefresh"
    />

    <theDocClaimsModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

import {
  BButton, VBToggle, BCollapse, BCard,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import vSelect from 'vue-select'
import theDocClaimsModal from '@/modules/claimsLitigation/claimsModal/doc/theDocClaimsModal.vue'
import areYouSure from '@/mixins/areYouSure'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import TheDebtorIncomeModal from './TheDebtorIncomeModal.vue'
import TheDebtorPropertyModal from './TheDebtorPropertyModal.vue'

export default {
  components: {
    BButton,
    TheDebtorIncomeModal,
    TheDebtorPropertyModal,
    vSelect,
    BCollapse,
    BCard,
    theDocClaimsModal,
    flatPickr,
  },
  mixins: [areYouSure],
  data() {
    return {
      type: 'borrower',
      editItem: true,
      formData: {
        title: null,
        file: null,
        date_document: null,
        comment: null,
        file_title: null,
        tags: [],
      },
      gender: [
        {
          title: 'Мужской',
          value: 'Мужской',
        },
        {
          title: 'Женский',
          value: 'Женский',
        },
      ],
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    moment() {
      return moment
    },
    ...mapState('cases', ['debtListId', 'debtDocument']),

    age() {
      const age = this.debtListId.BORROWER.BIRTH_DATE?.slice(6.9)
      return new Date().getFullYear() - age
    },

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },
  },
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    ...mapActions('cases', [
      'FETCH_SK_STATEMENT_OF_DEBT',
      'UPDATE_SK_DEBT_SK_CONTACT',
      'FETCH_DEBT_PROPERTY_MODAL',
      'DELETE_DEBT_DOCUMENT',
      'FETCH_DEBT_DOCUMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
    ]),

    // ******************** document **************************//
    openDocModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modalClaimsDoc')
      })
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData.file) {
        this.formData.file.forEach(key => {
          if (key) formData.append('file', key)
        })
      }
      if (this.formData.tags) {
        this.formData.tags.forEach(key => {
          if (key) formData.append('tags', key)
        })
      }

      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.debtListId.BORROWER.CONTENT_TYPE)
      formData.append('object_id', this.debtListId.BORROWER.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEditDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }

      if (this.formData?.tags) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.debtListId.BORROWER.CONTENT_TYPE)
      formData.append('object_id', this.debtListId.BORROWER.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.comment = ''
        this.formData.tags = []
        this.formData.file_title = null
        this.$bvModal.hide('addEdiClaimstDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_DEBT_DOCUMENT(id)
            .then(() => {
              this.docRefresh()
              this.$_okToast()
            })
            .catch(err => {
              this.$_errorToast(err)
            })
        }
      })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.debtListId.BORROWER.CONTENT_TYPE,
        object_id: this.debtListId.BORROWER.id,
        ...data,
      })
    },
    // ******************** document **************************//
    guarantor(e) {
      const age = e?.slice(6.9)
      return new Date().getFullYear() - age
    },

    cancel() {
      this.editItem = true
    },

    openIncomeModal() {
      this.$refs.incomeModal.open()
      this.workModal()
    },

    workModal(data) {
      this.FETCH_SK_STATEMENT_OF_DEBT({
        id: this.debtListId.BORROWER.id,
        ...data,
      })
    },

    openPropertyModal() {
      this.$refs.propertyModal.open()
    },

    propertyRefresh(data) {
      this.FETCH_DEBT_PROPERTY_MODAL({
        id: this.debtListId.BORROWER.id,
        ...data,
      })
    },

    updateSkContact() {
      delete this.debtListId.modified_date
      this.UPDATE_SK_DEBT_SK_CONTACT({
        id: this.debtListId.BORROWER.id,
        GENDER: this.debtListId.BORROWER.GENDER,
        BIRTH_DATE: this.debtListId.BORROWER.BIRTH_DATE,
      }).then(() => {
        this.editItem = true
        this.$_okToast()
      })
    },
  },
}
</script>
<style>
.vb__collapse {
  background-color: #f6f6f6 !important;
  border: 0;
}
</style>
