<template>
  <div>
    <div class="w-100 bg-light p-1 mb-2 rounded d-flex justify-content-between">
      <p class="mb-0">
        Суммы
      </p>
      <p class="mb-0">
        <BButton
          v-if="skDebtJudicalWorkFilterdDate.id"
          style="padding: 3px"
          :variant="!editItem ? 'warning' : 'light'"
          class="rounded-circle mr-50"
          @click="editItem = !editItem"
        >
          <feather-icon icon="EditIcon" />
        </BButton>
      </p>
    </div>
    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav">
          <p>Сумма задолженности:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_DEBT"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма ОД:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_OD"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма процентов:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_PERCENT"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма ЧВ:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_XZ"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма пени ЧВ:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_PENI_XZ"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма пени:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_PENI"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма ЮУ:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_UY"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма ГП:</p>
          <b-form-input
            v-model="skDebtJudicalWorkFilterdDate.JUD_GP"
            :plaintext="editItem"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="ref"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateJudicalWorkSummi"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BButton,
    BFormInput,
  },
  data() {
    return {
      editItem: true,
    }
  },
  computed: {
    ...mapState('cases', ['skDebtJudicalWorkFilterdDate']),
  },
  methods: {
    ...mapActions('cases', ['UPDATE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE', 'FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE']),

    updateJudicalWorkSummi() {
      delete this.skDebtJudicalWorkFilterdDate.modified_date
      this.UPDATE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({
        id: this.skDebtJudicalWorkFilterdDate.id,
        ...this.skDebtJudicalWorkFilterdDate,
      })
        .then(() => {
          this.editItem = true
          this.$_okToast()
        })
    },

    ref() {
      this.editItem = true
      this.FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({ id: this.skDebtJudicalWorkFilterdDate.id })
    },
  },
}
</script>
