<template>
  <BModal
    v-model="modalState"
    size="xl"
    centered
    title="История платежей"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    @show="openHisPlatModal"
  >
    <b-card>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="['5', '10', '100']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="ml-1 text-nowrap"> строк( {{ debtListPaymentHistoryModal.count }} )</span>
          <!-- из {{ props.total }} -->
        </div>
        <div>
          <b-pagination
            v-model="params.page"
            :total-rows="debtListPaymentHistoryModal.count"
            :per-page="params.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
    <BTable
      class="my-2"
      :items="debtListPaymentHistoryModal.results"
      :fields="fields"
      show-empty
      responsive
      :busy="loader"
      empty-text="Нет данных"
    >
      <template #empty>
        <empty />
      </template>
      <template #table-busy>
        <div class="d-flex align-items-center flex-column p-3">
          <b-spinner
            variant="primary"
          />
          <span>Загрузка...</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <p
          class="cursor-pointer text-underline text-primary mb-0"
          @click="openEdit(data.item)"
        >
          Подробно
        </p>
      </template>
    </BTable>

    <ThePaymentEdit
      ref="paymentEdit"
      :debtor="debtor"
    />
  </BModal>
</template>

<script>
import {
  BTable,
  BModal,
  BPagination,
  BFormSelect,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import empty from '@/components/empty.vue'
import ThePaymentEdit from './ThePaymentEdit.vue'

export default {
  components: {
    BTable,
    BModal,
    BCard,
    BPagination,
    BFormSelect,
    ThePaymentEdit,
    empty,
    BSpinner,
  },
  data() {
    return {
      stickyHeader: true,
      loader: false,
      modalState: false,
      debtor: {},
      fields: [
        {
          key: 'PAY_DATE',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'PAY_AMOUNT',
          label: 'Сумма',
          sortable: true,
        },
        {
          key: 'id',
          label: 'ИД',
        },
        {
          key: 'sign_admission',
          label: 'Признак поступления ',
        },
        {
          key: 'collector_share',
          label: 'Доля коллектора ',
        },
        {
          key: 'not_credited_manager',
          label: 'Не засчитывается менеджеру ',
        },
        {
          key: 'actions',
          label: 'Действия',
        },
      ],
      params: {
        page_size: 100,
        page: 1,
      },
    }
  },
  computed: {
    ...mapState('cases', ['debtListPaymentHistoryModal']),
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL']),

    async openHisPlatModal() {
      try {
        this.loader = true
        await this.FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ id: this.$route.params.id, ...this.params })
      } catch (error) {
        await this.$_errorToast()
      }
      this.loader = false
    },

    // eslint-disable-next-line camelcase
    async changePerPage(page_size) {
      try {
        // eslint-disable-next-line camelcase
        this.params.page_size = page_size
        this.params.page = 1
        await this.FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ id: this.$route.params.id, ...this.params })
      } catch (error) {
        await this.$_errorToast()
      }
    },

    changePage(value) {
      this.params.page = value
      this.FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ id: this.$route.params.id, ...this.params })
    },

    openEdit(item) {
      this.debtor = item
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.paymentEdit.open()
        }, 100)
      })
    },

    open() {
      this.modalState = true
    },
    close() {
      this.modalState = false
    },
  },
}
</script>

<style>
.text-underline {
  text-decoration: underline;
}
</style>
