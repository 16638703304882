<template>
  <BModal
    id="modalClaimsEditModalId"
    size="md"
    centered
    title="Редактировать"
    ok-variant="warning"
    ok-title="Сохранить"
    cancel-title="Отмена"
    cancel-variant="outline-secondary"
    responsive
    no-close-on-backdrop
    @show="openShowClaimsModal"
    @ok="onSubmit"
  >
    <b-form-group label="Дата отправки">
      <VcDatePicker
        v-model="claim.JUD_DATE_SENT"
        show-format="YYYY.MM.DD"
      />
    </b-form-group>

    <b-form-group label="Дата получения">
      <VcDatePicker
        v-model="claim.RECEIPT_DATE"
        show-format="YYYY.MM.DD"
      />
    </b-form-group>

    <b-form-group label="На кого">
      <b-form-input
        v-model="claim.CONTACT"
      />
    </b-form-group>
  </BModal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import VcDatePicker from '@/components/VcDatePicker.vue'
import { mapActions } from 'vuex'
// import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'ClaimsEditModal',
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    VcDatePicker,
    // flatPickr,
  },
  props: {
    editClaims: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      claim: {
        JUD_DATE_SENT: '',
        RECEIPT_DATE: '',
        CONTACT: '',
      },
    }
  },
  methods: {
    ...mapActions('cases', ['UPDATE_CLAIMS']),
    onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.UPDATE_CLAIMS({
        id: this.editClaims.id,
        ...this.claim,
      })
        .then(() => {
          this.$emit('refresh')
          this.$_okToast()
          this.$bvModal.hide('modalClaimsEditModalId')
        })
    },

    openShowClaimsModal() {
      this.$nextTick(() => {
        if (this.editClaims) {
          this.claim.JUD_DATE_SENT = this.editClaims.JUD_DATE_SENT
          this.claim.RECEIPT_DATE = this.editClaims.RECEIPT_DATE
          this.claim.CONTACT = this.editClaims.CONTACT
        } else {
          this.claim.JUD_DATE_SENT = ''
          this.claim.RECEIPT_DATE = ''
          this.claim.CONTACT = ''
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
