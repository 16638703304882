<template>
  <div>
    <!--          <b-button-->
    <!--            v-b-tooltip.hover.v-primary-->
    <!--            size="sm"-->
    <!--            :title="props.item.file_title"-->
    <!--            variant="primary"-->
    <!--            @click="downloadImage(props.item)"-->
    <!--          >-->
    <!--            <feather-icon icon="FileIcon" />-->
    <!--          </b-button>-->
    <BModal
      id="claimsDocModal"
      centered
      title="Документы"
      ok-variant="info"
      ok-title="Да"
      cancel-title="Отмена"
      hide-footer
      responsive
      size="xl"
      no-close-on-backdrop
    >
      <div class="text-right">
        <b-button
          variant="primary"
          @click="openDocAddModal"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Добавить документы
        </b-button>
      </div>
      <BTable
        class="my-2"
        :items="docvalue.results"
        :fields="fields"
        show-empty
        empty-text="Нет данных"
      >
        <template #cell(tags)="props">
          <div
            v-for="(item, index) in props.item.tags"
            :key="index"
          >
            <b-badge variant="success">
              {{ item.name }} {{ " " }}
            </b-badge>
          </div>
        </template>
        <template #cell(file)="props">
          <div class="d-flex flex-wrap">
            <b-button
              v-for="(t, index) in props.item.files"
              :key="index"
              v-b-tooltip.hover.v-primary
              size="sm"
              :title="props.item.file_title"
              variant="primary"
              class="mr-1 mb-1 position-relative"
              @click.stop="downloadImage(t)"
            >
              <b-badge
                variant="danger"
                class="bbg_t"
                @click.stop="deleteFileData(t.id)"
              >
                x
              </b-badge>
              <feather-icon icon="FileIcon" />
              {{ t.file_title }}
            </b-button>
          </div>
        </template>
        <template #cell(actions)="props">
          <BButton
            class="p-50 mr-1"
            variant="warning"
            @click="editDocModal(props.item)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </BButton>
          <BButton
            class="p-50"
            variant="danger"
            @click="$emit('deleteDataDocument', props.item.id)"
          >
            <feather-icon icon="XIcon" />
          </BButton>
        </template>
      </BTable>
      <BCPagination
        :count="docvalue.count"
        @input="changePag"
      />
    </BModal>

    <addEditClaimsDocsModal
      :type="type"
      :form-data="formData"
      :edit-doc="editDoc"
      @createDoc="$emit('createDoc')"
      @editDocuments="editDocuments"
      @hideModal="$emit('hideModal')"
    />

    <modalFileSecond3
      :file-data="fileData"
    />
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BTable,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import addEditClaimsDocsModal from '@/modules/claimsLitigation/components/addEditClaimsDocsModal.vue'
import { mapActions } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import modalFileSecond3 from '../claimsModal/fileView.vue'

export default {
  name: 'TheDocModal',
  components: {
    BModal,
    BButton,
    BTable,
    addEditClaimsDocsModal,
    modalFileSecond3,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [areYouSure],
  props: {
    docvalue: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'Дата создания',
          key: 'created_date',
          sortable: true,
        },
        {
          label: 'Дата документа',
          key: 'date_document',
        },
        {
          label: 'Вид документ',
          key: 'tags',
        },
        {
          label: 'Файлы',
          key: 'file',
        },
        {
          label: 'Полезная информация',
          key: 'title',
        },
        {
          label: 'Комментарий',
          key: 'comment',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      editDoc: {},
      fileData: {},
    }
  },
  methods: {
    ...mapActions('cases', ['DELETE_DEBT_DOCUMENT_FILE_DATA']),

    deleteFileData(id) {
      this.$_showAreYouSureModal()
        .then(e => {
          if (e) {
            this.DELETE_DEBT_DOCUMENT_FILE_DATA(id)
              .then(() => {
                this.$emit('docRefresh')
              })
          }
        })
    },

    changePag(data) {
      this.$emit('docRefresh', data)
    },

    downloadImage(t) {
      this.fileData = t
      this.$nextTick(() => {
        this.$bvModal.show('modalFileSecond3')
      })
    },

    editDocuments() {
      this.$emit('editDocuments', this.editDoc.id)
      this.$emit('hideModal')
    },

    openDocAddModal() {
      this.editDoc = {}
      this.$emit('hideModal')
      this.$nextTick(() => {
        this.$bvModal.show('addEdiClaimstDoc2')
      })
    },

    editDocModal(item) {
      this.editDoc = item
      this.$nextTick(() => {
        this.$bvModal.show('addEdiClaimstDoc2')
      })
    },
  },
}
</script>

<style>
.file__w{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.bbg_t {
  position: absolute!important;
  right: -8px!important;
  top: -8px!important;
}
</style>
