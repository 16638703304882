<template>
  <BModal
    ref="modal"
    size="xl"
    centered
    title="Парсинг"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    responsive
    no-close-on-backdrop
  >
    <BTable
      class="my-2"
      :items="items"
      :fields="fields"
      show-empty
      responsive
      empty-text="Нет данных"
    >
      <template #cell(documents)>
        <p
          class="hover-text-underline"
          @click="openDocumentModal"
        >
          Документы
        </p>
      </template>
    </BTable>
    <TheDocumentsModal ref="documents" />
  </BModal>
</template>

<script>
import { BModal, BTable } from 'bootstrap-vue'
import TheDocumentsModal from '@components/modules/TheDocumentsModal.vue'

export default {
  components: {
    BModal,
    BTable,
    TheDocumentsModal,
  },
  data() {
    return {
      fields: [
        // дата , источник ,На кого , Документы , Комментарий , Действия
        {
          label: 'Дата',
          key: 'date',
          sortable: true,
        },
        {
          label: 'Источник',
          key: 'source',
          sortable: true,
        },
        {
          label: 'На кого',
          key: 'to',
          sortable: true,
        },
        {
          label: 'Документы',
          key: 'documents',
          sortable: true,
        },
        {
          label: 'Комментарий',
          key: 'comment',
          sortable: true,
        },
      ],
      items: [
        {
          date: 'date',
          source: 'source',
          to: 'to',
          files: 'files',
          comment: 'comment',
          actions: 'actions',
        },
      ],
    }
  },
  methods: {
    openDocumentModal() {
      this.$refs.documents.open()
    },
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style>
</style>
