<template>
  <div>
    <TheHeader />
    <TheBody />
  </div>
</template>

<script>
import TheHeader from '../components/TheHeader.vue'
import TheBody from '../components/TheBody.vue'

export default {
  name: 'Home',
  components: {
    TheHeader,
    TheBody,
  },
}
</script>
