<template>
  <div class="mt-1">
    <BTable
      :busy="load"
      :fields="fields"
      :items="skDebtAction.results"
      show-empty
      hover
    >
      <template #empty>
        <empty />
      </template>
      <template #table-busy>
        <div class="d-flex align-items-center flex-column p-3">
          <b-spinner
            variant="primary"
          />
          <span>Загрузка...</span>
        </div>
      </template>
      <template #cell(title)="props">
        <b-button
          size="sm"
          variant="light"
          class="text-primary text-left"
          @click="onRowSelected(props.item)"
        >
          {{ props.item.title }}
        </b-button>
      </template>
      <template #cell(participants)="props">
        <span
          v-for="(item, index) in props.item.participants"
          :key="index"
        >
          {{ item.name }}
        </span>
      </template>
      <template #cell(created_date)="props">
        {{ props.item.created_date }}
      </template>
      <template #cell(actions)="props">
        <div class="buttons">
          <b-button
            v-for="(item, index) in props.item.current_step.change_to"
            :key="index"
            v-b-tooltip.hover="{ variant: item.color }"
            :title="item.title"
            size="sm"
            :variant="item.color"
            @click="onChangeStatus(props, item)"
          >
            <feather-icon :icon="item.icon" />
          </b-button>

          <b-button
            v-if="props.item.current_step.change_to && props.item.current_step.change_to.length"
            v-b-tooltip.hover.v-warning
            title="Изменить"
            variant="warning"
            size="sm"
            @click="onEdit(props.item)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </b-button>
        </div>
      </template>
      <template #cell(status)="props">
        <b-form-checkbox
          :value="props.item.is_guarantor"
          disabled
          checked="true"
          name="check-button"
          switch
          inline
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </template>
      <template #cell(action_type)="data">
        <span v-if="data.item.action_type">{{ data.item.action_type.name }}</span>
      </template>
    </BTable>

    <Pagination
      class="px-2 mt-2"
      :users_count="skDebtAction.count"
      @changePaginationValue="changePaginationValue"
    />

    <actionTableViewModal
      :table-val="tableVal"
    />
  </div>
</template>

<script>
import {
  BTable,
  VBTooltip,
  BButton,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import actionTableViewModal from '@/modules/actions/components/actionTableViewModal.vue'
import empty from '@/components/empty.vue'
import Pagination from '@/components/pagination.vue'
import FIELDS from './fields'

export default {
  components: {
    BTable,
    BButton,
    BSpinner,
    BFormCheckbox,
    empty,
    actionTableViewModal,
    Pagination,
  },
  props: {
    editItemAction1: {
      type: Object,
      default: () => ({}),
    },
    datas: {
      type: String,
      default: () => '',
    },
    keyS: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields: FIELDS.fields,
      tableVal: {},
      load: false,
      filter: {
        page: 1,
        page_size: 10,
      },
    }
  },
  computed: {
    ...mapState('cases', ['debtListId']),
    ...mapState('actions', ['skDebtAction']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('actions', ['FETCH_DEBT_ACTION', 'UPDATE_ACTIONS']),

    changePaginationValue({ page, page_size }) {
      this.filter.page = page
      // eslint-disable-next-line camelcase
      this.filter.page_size = page_size

      this.refresh()
    },

    async refresh(data) {
      try {
        this.load = true
        await this.FETCH_DEBT_ACTION({
          content_type: this.debtListId.CONTENT_TYPE,
          object_id: this.$route.params.id,
          current_step: this.keyS,
          ...data,
          ...this.filter,
        })
      } catch (error) {
        await this.$_errorToast()
      }
      this.load = false
    },

    onRowSelected(item) {
      this.tableVal = item
      this.$nextTick(() => {
        this.$bvModal.show('actionViewModal')
      })
    },

    onChangeStatus(props, item) {
      this.$bvModal
        .msgBoxConfirm(
          `Вы действительно хотите ${item.title} ?`,
          {
            headerBgVariant: item.color,
            title: 'Внимание',
            size: 'sm',
            okVariant: item.color,
            okTitle: 'Да',
            cancelTitle: 'Нет',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(async value => {
          if (!value) return
          this.UPDATE_ACTIONS({
            current_step: item.id,
            id: props.item.id,
          })
            .then(() => {
              this.refresh()
            })
        })
    },

    onEdit(item) {
      this.$nextTick(() => {
        this.$emit('openEditModal', item)
      })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
<style lang="scss">
.buttons {
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }

  :nth-child(1) {
    margin-left: 0;
  }
}
</style>
