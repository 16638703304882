<template>
  <div>
    <div class="w-100 bg-light p-1 rounded d-flex justify-content-between">
      <p class="mb-0">
        Правопреемство
      </p>
      <!--      <p class="mb-0">-->
      <!--        <BButton-->
      <!--          style="padding: 3px"-->
      <!--          :variant="!editItem ? 'warning' : 'light'"-->
      <!--          class="rounded-circle mr-50"-->
      <!--          @click="editItem = !editItem"-->
      <!--        >-->
      <!--          <feather-icon icon="EditIcon" />-->
      <!--        </BButton>-->
      <!--      </p>-->
    </div>
    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav">
          <p>Оформление:</p>
          <p>{{ debtListId.BASIS.SUCCESSION }}</p>
        </div>
        <div class="filds__nav">
          <p>Статус:</p>
          <p>{{ debtListId.BASIS.SUCCESSION_STATUS }}</p>
        </div>
        <div class="filds__nav">
          <p>Дата определения:</p>
          <p>{{ debtListId.BASIS.DATE_SUCCESSION }}</p>
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="editItem = true"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateZaym"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      editItem: true,
    }
  },
  computed: {
    ...mapState('cases', ['debtListId']),
  },
  methods: {
    ...mapActions('cases', ['UPDATE_SK_DEBT_ZAYM']),

    updateZaym() {
      delete this.debtListId.BASIS.DO_STAGE
      this.UPDATE_SK_DEBT_ZAYM({
        id: this.debtListId.id,
        ...this.debtListId.BASIS,
      })
        .then(() => {
          this.editItem = true
          this.$_okToast()
        })
    },
  },
}
</script>
