<template>
  <div>
    <div class="w-100 bg-light p-1 rounded d-flex justify-content-between">
      <p class="mb-0">
        Банкрот
      </p>
      <BButton
        style="padding: 3px"
        :variant="!editItem ? 'warning' : 'light'"
        class="rounded-circle mr-50"
        @click="editItem = !editItem"
      >
        <feather-icon icon="EditIcon" />
      </BButton>
    </div>

    <div class="d-flex justify-content-between w-100 p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav w-100">
          <p>Банкрот:</p>
          <v-select
            v-if="!editItem"
            v-model="debtListId.BASIS.BANKRUPT"
            class="w-100"
            label="title"
            :reduce="p => p.key"
            :options="STATUS"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ BANKRUPT }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="editItem = true"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateBankrupt"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BButton,
    vSelect,
  },
  data() {
    return {
      editItem: true,
      STATUS: [
        {
          title: 'Да',
          key: 'Y',
        },
        {
          title: 'Нет',
          key: 'N',
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['debtListId']),

    BANKRUPT() {
      let text = ''
      // eslint-disable-next-line default-case
      switch (this.debtListId.BASIS.BANKRUPT) {
        case 'Y':
          text = 'Да'
          break
        case 'N':
          text = 'Нет'
          break
      }
      return text
    },
  },
  methods: {
    ...mapActions('cases', ['UPDATE_SK_DEBT_ZAYM']),

    async updateBankrupt() {
      const req = {
        id: this.debtListId.id,
        ...this.debtListId.BASIS,
      }
      await this.UPDATE_SK_DEBT_ZAYM(req)
      this.editItem = true
      await this.$_okToast()
    },
  },
}
</script>
