<template>
  <div>
    <div class="w-100 bg-light p-1 rounded d-flex justify-content-between">
      <p class="mb-0">
        Займ
      </p>
      <BButton
        v-if="debtListId.BASIS.id"
        style="padding: 3px"
        :variant="!editItem ? 'warning' : 'light'"
        class="rounded-circle mr-50"
        @click="editItem = !editItem"
      >
        <feather-icon icon="EditIcon" />
      </BButton>
    </div>

    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav">
          <p>Цель займа:</p>
          <b-form-input
            v-model="debtListId.BASIS.PURPOSE_OF_LOAN"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Сумма займа:</p>
          <b-form-input
            v-model="debtListId.BASIS.DEBT_SUM"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Срок займа:</p>
          <b-form-input
            v-model="debtListId.BASIS.DEBT_PERIOD"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Сумма задолженности:</p>
          <b-form-input
            v-model="debtListId.BASIS.DEBT"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Срок просрочки:</p>
          <b-form-input
            v-model="debtListId.BASIS.DELAY"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Дата уведомления о передаче в КА:</p>
          <b-form-input
            v-model="debtListId.BASIS.NOTICE_DATE"
            :plaintext="editItem"
          />
        </div>
        <div class="filds__nav">
          <p>Дата договора:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="debtListId.BASIS.DEBT_START_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            {{ debtListId.BASIS.DEBT_START_DATE }}
          </p>
        </div>
        <div class="filds__nav">
          <p>Дата окончания договора:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="debtListId.BASIS.DEBT_END_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            {{ debtListId.BASIS.DEBT_END_DATE }}
          </p>
        </div>
        <div class="filds__nav">
          <p>Дата закрытия долга:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="debtListId.BASIS.CARD_CLOSE_DATE"
            class="form-control"
            :config="configdateTimePicker"
          />
          <p v-else>
            {{ debtListId.BASIS.CARD_CLOSE_DATE }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="cancel"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateZaym"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  components: {
    BButton,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      editItem: true,
      configdateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    ...mapState('cases', ['debtListId']),
  },
  methods: {
    ...mapActions('cases', ['UPDATE_SK_DEBT_ZAYM', 'FETCH_SK_STATEMENT_OF_DEBT']),

    updateZaym() {
      delete this.debtListId.modified_date
      delete this.debtListId.BASIS.DO_STAGE
      delete this.debtListId.BASIS.CURRENCY
      this.UPDATE_SK_DEBT_ZAYM({
        id: this.debtListId.id,
        ...this.debtListId.BASIS,
      })
        .then(() => {
          this.FETCH_SK_STATEMENT_OF_DEBT({ id: this.$route.params.id })
          this.editItem = true
          this.$_okToast()
        })
    },

    cancel() {
      this.editItem = true
    },
  },
}
</script>
