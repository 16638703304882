<template>
  <div>
    <div class="w-100 bg-light p-1 rounded d-flex justify-content-between">
      <p class="mb-0">
        Долговое обязательство
      </p>
      <BButton
        v-if="debtListId.BASIS.id"
        style="padding: 3px"
        :variant="!editItem ? 'warning' : 'light'"
        class="rounded-circle mr-50"
        @click="editItem = !editItem"
      >
        <feather-icon icon="EditIcon" />
      </BButton>
    </div>
    <div class="d-flex justify-content-center p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav w-100">
          <p>VIP должник:</p>
          <b-form-input
            v-model="debtListId.BASIS.DOCUMENT"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Взыскатель</p>
          <b-form-input
            v-model="debtListId.CLAIMER_COMPANY_NAME"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Наименование документа:</p>
          <b-form-input
            v-model="debtListId.BASIS.DEBT_TYPE"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Дело пайщика:</p>
          <b-form-input
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Комментарии:</p>
          <p
            v-if="debtListId.CONTENT_TYPE"
            class="hover-text-underline"
            @click="openModal('comments')"
          >
            Комментариев
          </p>
        </div>

        <div class="filds__nav">
          <p>Документы:</p>
          <p
            v-if="debtListId.CONTENT_TYPE"
            class="hover-text-underline"
            @click="openDocModal"
          >
            Документы
          </p>
        </div>

        <div class="filds__nav">
          <p>Имущество:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="debtListId.BASIS.PROPERTY"
            class="form-control"
            :config="configDateTimePicker"
          />
          <p v-else>
            {{ debtListId.BASIS.PROPERTY }}
          </p>
        </div>

        <div class="filds__nav">
          <p>Дата выхода в просрочку:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="debtListId.BASIS.DEBT_DATE"
            class="form-control"
            :config="configDateTimePicker"
          />
          <p v-else>
            {{ debtListId.BASIS.DEBT_DATE }}
          </p>
        </div>

        <div class="filds__nav">
          <p>Дата исковой давности:</p>
          <flat-pickr
            v-if="!editItem"
            v-model="debtListId.BASIS.LIMIT_PERIOD_DATE"
            class="form-control"
            :config="configDateTimePicker"
          />
          <p v-else>
            {{ debtListId.BASIS.LIMIT_PERIOD_DATE }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="cancel"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updatePromissoryNote"
      >
        Сохранить
      </b-button>
    </div>

    <!--//comment modal-->
    <TheCommentsModal
      ref="comments"
      :comment="comments"
      :comment-message="commentDebt.results"
      @createComment="createComment"
      @refresh="refresh"
      @deleteComment="deleteComment"
    />
    <!--//comment modal end-->

    <!--//document modal-->
    <theDocModal
      :type="type"
      :additional1="additional1"
      :docvalue="debtDocument"
      :form-data="formData"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />

    <TheParsingModal ref="parsing" />
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import TheCommentsModal from '@components/modules/TheCommentsModal.vue'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import areYouSure from '@/mixins/areYouSure'
import theDocModal from '@/components/modalDocDebts/theDocModal.vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import TheParsingModal from './TheParsingModal.vue'

export default {
  components: {
    BButton,
    BFormInput,
    theDocModal,
    TheCommentsModal,
    TheParsingModal,
    flatPickr,
  },
  mixins: [areYouSure],
  data() {
    return {
      type: 'basis',
      additional1: true,
      editItem: true,
      comments: {
        message: '',
      },
      formData: {
        title: '',
        file: '',
        date_document: '',
        comment: '',
        tags: [],
        file_title: '',
      },
      configDateTimePicker: {
        dateFormat: 'd.m.Y',
        locale: Russian,
      },
    }
  },
  computed: {
    ...mapState('cases', [
      'debtListId',
      'commentDebt',
      'debtDocument',
    ]),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },
  },
  methods: {
    ...mapActions('cases', [
      'UPDATE_SK_DEBT_ZAYM',
      'CREATE_COMMENT',
      'FETCH_COMMENT_DEBT',
      'DELETE_COMMENT',
      'CREATE_DEBT_DOCUMENT',
      'FETCH_DEBT_DOCUMENT',
      'DELETE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
    ]),

    cancel() {
      this.editItem = true
    },

    // ********************** Promissory Note ********************** //
    updatePromissoryNote() {
      delete this.debtListId.modified_date
      delete this.debtListId.BASIS.DO_STAGE
      this.UPDATE_SK_DEBT_ZAYM({
        id: this.debtListId.id,
        ...this.debtListId.BASIS,
      })
        .then(() => {
          this.editItem = true
          this.$_okToast()
        })
    },
    // ********************** Promissory Note end ********************** //

    // ******************* comments methods *********************** //
    createComment() {
      this.CREATE_COMMENT({
        author: this.getUserId,
        content_type: this.debtListId.CONTENT_TYPE,
        object_id: this.debtListId.id,
        ...this.comments,
      })
        .then(() => {
          this.refresh()
          this.$_okToast()
          this.comments.message = ''
        })
    },

    refresh() {
      this.FETCH_COMMENT_DEBT({
        content_type: this.debtListId.CONTENT_TYPE,
        object_id: this.debtListId.id,
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
          }
        })
    },
    // ***************** comments methods end ********************* //

    openModal(modal) {
      const enm = ['documents', 'comments', 'parsing']
      if (enm.includes(modal)) {
        this.$refs[modal].open()
      } else {
        console.error('Нет такого модального окна ', JSON.stringify(modal))
      }
    },

    // *********************** document *****************************//
    openDocModal() {
      this.$bvModal.show('modalDoc')
      this.docRefresh()
    },

    createDoc() {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      for (const key of this.formData?.file) {
        if (typeof key !== 'undefined') formData.append('file', key)
      }
      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.debtListId.CONTENT_TYPE)
      formData.append('object_id', this.debtListId.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.$bvModal.hide('addEditDoc')
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      if (this.formData?.file) {
        for (const key of this.formData?.file) {
          if (typeof key !== 'undefined') formData.append('file', key)
        }
      }
      if (this.formData?.tags) {
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
      }
      formData.append('title', this.formData?.title)
      formData.append('comment', this.formData?.comment)
      formData.append('author', this.getUserId)
      formData.append('content_type', this.debtListId.CONTENT_TYPE)
      formData.append('object_id', this.debtListId.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEditDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.action_type = null
        this.formData.comment = ''
        this.formData.tags = []
        this.$bvModal.hide('addEditDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.debtListId.CONTENT_TYPE,
        object_id: this.debtListId.id,
        ...data,
      })
    },
    // ******************** document end **************************//
  },
}
</script>
