export default {
  fields: [
    {
      key: 'id',
      label: '№',
    },
    {
      key: 'title',
      label: 'НАИМЕНОВАНИЕ',
    },
    {
      key: 'action_type',
      label: 'Вид действия',
    },
    {
      key: 'planned_time',
      label: 'Контрольная дата',
    },
    {
      key: 'created_date',
      label: 'Дата создания',
    },
    {
      key: 'execution_end',
      label: 'Дата завершения',
    },
    {
      key: 'status',
      label: 'Поручитель',
    },
    {
      key: 'participants',
      label: 'Ответственный',
    },
    {
      key: 'author',
      label: 'Автор',
    },
    {
      key: 'actions',
      label: 'Действия',
    },
  ],
}
