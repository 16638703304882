<template>
  <div>
    <BModal
      id="ipModal"
      size="md"
      centered
      title="ИП"
      ok-variant="primary"
      ok-title="Сохранить"
      cancel-title="Отменить"
      cancel-variant="outline-secondary"
      responsive
      no-close-on-backdrop
      @cancel="cancel"
      @show="openShowModal"
      @ok="updateIp"
    >
      <div class="text-right w-100">
        <b-button
          class="rounded-circle p-50"
          size="sm"
          variant="secondary"
          @click="editItem = !editItem"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </div>
      <div class="w-100 m-auto text-center">
        <h2 class="mb-2">
          Основное
        </h2>
        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Тип:
            </p>
          </div>
          <v-select
            v-if="!editItem"
            v-model="ipValue.TYPE"
            class="w-50"
            label="title"
            :reduce="type => type.value"
            :options="TYPE"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ typeIp }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Номер
            </p>
          </div>
          <b-form-input
            v-model="ipValue.SSP_NUMBER_IP"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class=" d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата возбуждения:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="ipValue.SSP_IP_START_DATE"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ ipValue.SSP_IP_START_DATE }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Осп:
            </p>
          </div>
          <v-select
            v-if="!editItem"
            v-model="ipValue.SSP_OSP_NAME"
            class="w-50"
            label="title"
            :reduce="staisfaction => staisfaction.value"
            :options="[]"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            {{ ipValue.SSP_OSP_NAME }}
          </p>
        </div>

        <div class=" d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата подачи:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="ipValue.SSP_DATE"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ ipValue.SSP_DATE }}
          </p>
        </div>

        <div class=" d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата окончания:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="ipValue.SSP_IP_END_DATE"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ ipValue.SSP_IP_END_DATE }}
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Причина окончания:
            </p>
          </div>
          <v-select
            v-if="!editItem"
            v-model="ipValue.SSP_COM_END_IP"
            class="w-100"
            label="title"
            :reduce="p => p.value"
            :options="SSP_COM_END_IP"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
          <p v-else>
            <!--            {{ ipValue.SSP_COM_END_IP }}-->
            {{ ssp }}
          </p>
        </div>

        <div class=" d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата возврата ИД:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="ipValue.SSP_RETURN_DATE"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ ipValue.SSP_RETURN_DATE }}
          </p>
        </div>

        <div class="d-flex w-100 my-1">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p
              class="w-100"
            >
              Документы
            </p>
          </div>
          <p
            class="a__click w-auto"
            @click="openDocModal"
          >
            {{ debtDocument.count }}
            Документы
          </p>
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Комментарии
            </p>
          </div>
          <p
            class="a__click w-auto"
            @click="openModal('comments')"
          >
            Комментариев
          </p>
        </div>

        <div class=" d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Дата обновления ФССП:
            </p>
          </div>
          <VcDatePicker
            v-if="!editItem"
            v-model="ipValue.FSSP_UPDATE_DATE"
            class="w-50"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': false }"
          />
          <p v-else>
            {{ ipValue.FSSP_UPDATE_DATE }}
          </p>
        </div>

        <h2 class="mb-2 mt-2">
          Дополнительно
        </h2>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма по данным взыскателя:
            </p>
          </div>
          <b-form-input
            v-model="ipValue.CLAIMER_SUM"
            :plaintext="editItem"
            class="w-50"
          />
        </div>

        <div class="d-flex w-100 my-50">
          <div class="w-50 text-right px-1 d-flex align-items-center">
            <p class="w-100">
              Сумма по данным ФССП:
            </p>
          </div>
          <b-form-input
            v-model="ipValue.FSSP_SUM"
            :plaintext="editItem"
            class="w-50"
          />
        </div>
      </div>
    </BModal>

    <TheCommentsModal
      ref="comments"
      :comment="comments"
      :comment-message="commentDebt.results"
      @createComment="createComment"
      @refresh="refresh"
      @deleteComment="deleteComment"
    />

    <theDocClaimsModal
      :type="type"
      :docvalue="debtDocument"
      :form-data="formData"
      :additional1="additional1"
      @deleteDataDocument="deleteDataDocument"
      @createDoc="createDoc1"
      @editDocuments="editDocuments"
      @hideModal="hideModal"
      @docRefresh="docRefresh"
    />
  </div>
</template>

<script>
import {
  BModal,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import VcDatePicker from '@/components/VcDatePicker.vue'
import vSelect from 'vue-select'
import TheCommentsModal from '@/components/modules/TheCommentsModal.vue'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import theDocClaimsModal from '@/modules/claimsLitigation/claimsModal/doc/theDocClaimsModal.vue'
import areYouSure from '@/mixins/areYouSure'

export default {
  name: 'IpModal',
  components: {
    BModal,
    BFormInput,
    BButton,
    VcDatePicker,
    vSelect,
    TheCommentsModal,
    theDocClaimsModal,
  },
  mixins: [areYouSure],
  props: {
    ipValue: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      type: 'ip',
      additional1: true,
      editItem: true,
      comments: {
        message: '',
      },
      formData: {
        title: '',
        file: '',
        date_document: '',
        comment: '',
        action_type: '',
        file_title: '',
        tags: [],
      },
      TYPE: [
        {
          title: 'Активное',
          value: 'active',
        },
        {
          title: 'Окончено фактом',
          value: 'fact_end',
        },
        {
          title: 'Окончено',
          value: 'end',
        },
      ],
      SSP_COM_END_IP: [
        {
          title: 'п. 3, ч. 1, ст. 46',
          // Невозможно установить местонахождение должника/имущества
          value: 'Impossible',
        },
        {
          title: 'п. 4, ч. 1, ст. 46',
          // Отсутствует имущество для обращения взыскания
          value: 'Absent',
        },
        {
          title: 'п. 1, ч. 1, ст. 47',
          // Фактическое исполнение
          value: 'Execute',
        },
        {
          title: 'п. 7, ч. 1, ст. 47',
          // Признание должника банкротом
          value: 'Bankrot',
        },
        {
          title: 'п. 8, ч. 1, ст. 47',
          // Направление ИД для удержания периодических платежей
          value: 'Hold',
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['commentDebt', 'debtDocument']),

    getUserId() {
      const { id } = useJwt.getUserData()
      return id
    },

    typeIp() {
      let typeText = ''
      // eslint-disable-next-line default-case
      switch (this.ipValue.TYPE) {
        case 'active':
          typeText = 'Активное'
          break
        case 'fact_end':
          typeText = 'Окончено фактом'
          break
        case 'end':
          typeText = 'Окончено'
          break
      }
      return typeText
    },

    ssp() {
      let ssp = ''
      // eslint-disable-next-line default-case
      switch (this.ipValue.SSP_COM_END_IP) {
        case 'Impossible':
          ssp = 'п. 3, ч. 1, ст. 46'
          break
        case 'Absent':
          ssp = 'п. 4, ч. 1, ст. 46'
          break
        case 'Execute':
          ssp = 'п. 1, ч. 1, ст. 47'
          break
        case 'Bankrot':
          ssp = 'п. 7, ч. 1, ст. 47'
          break
        case 'Hold':
          ssp = 'п. 8, ч. 1, ст. 47'
          break
      }
      return ssp
    },
  },
  methods: {
    ...mapActions('cases', [
      'UPDATE_IP_MODAL',
      'CREATE_COMMENT',
      'FETCH_COMMENT_DEBT',
      'DELETE_COMMENT',
      'CREATE_DEBT_DOCUMENT',
      'UPDATE_DEBT_DOCUMENT',
      'DELETE_DEBT_DOCUMENT',
      'FETCH_DEBT_DOCUMENT',
    ]),

    openShowModal() {
      this.docRefresh()
    },

    updateIp() {
      delete this.ipValue.modified_date
      delete this.ipValue.created_date
      this.UPDATE_IP_MODAL({
        id: this.ipValue.id,
        ...this.ipValue,
      })
        .then(() => {
          this.$emit('refresh')
          this.$_okToast()
          this.editItem = true
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    cancel() {
      this.$bvModal.hide('ipModal')
      this.editItem = true
    },

    // ******************* comments methods *********************** //
    openModal(modal = '') {
      const enm = ['comments']
      if (enm.includes(modal)) {
        this.$refs[modal].open()
      } else {
        console.error('Нет такого модального окна ', JSON.stringify(modal))
      }
    },

    createComment() {
      this.CREATE_COMMENT({
        author: this.getUserId,
        content_type: this.ipValue.CONTENT_TYPE,
        object_id: this.ipValue.id,
        ...this.comments,
      })
        .then(() => {
          this.refresh()
          this.$_okToast()
          this.comments.message = ''
        })
    },

    refresh() {
      this.FETCH_COMMENT_DEBT({
        content_type: this.ipValue.CONTENT_TYPE,
        object_id: this.ipValue.id,
      })
    },

    deleteComment(id) {
      this.$_showAreYouSureModal()
        .then(val => {
          if (val) {
            this.DELETE_COMMENT(id)
              .then(() => {
                this.refresh()
                this.$_okToast()
              })
          }
        })
    },
    // ***************** comments methods end ********************* //

    // ******************** document **************************//
    openDocModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modalClaimsDoc')
      })
      this.docRefresh()
    },

    createDoc1() {
      try {
        const formData = new FormData()
        formData.append('date_document', this.formData?.date_document)
        this.formData?.title && formData.append('title', this.formData?.title)
        // eslint-disable-next-line no-restricted-syntax
        for (const keys of this.formData?.file) {
          if (typeof keys !== 'undefined') formData.append('file', keys)
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const key of this.formData?.tags) {
          if (typeof key !== 'undefined') formData.append('tags', key)
        }
        // eslint-disable-next-line no-unused-expressions
      this.formData?.title && formData.append('comment', this.formData?.comment)

      // eslint-disable-next-line no-unused-expressions
      this.formData?.action_type && formData.append('action_type', this.formData?.action_type)

      formData.append('author', this.getUserId)
      formData.append('content_type', this.ipValue.CONTENT_TYPE)
      formData.append('object_id', this.ipValue.id)
      this.CREATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$_okToast()
          this.hideModal()
          this.$bvModal.hide('addEdiClaimstDoc')
        })
      } catch (e) {
        this.$_errorToast(e)
      }
    },

    editDocuments(value) {
      const formData = new FormData()
      formData.append('date_document', this.formData?.date_document)
      // eslint-disable-next-line no-restricted-syntax
      for (const key1 of this.formData?.file) {
        if (typeof key1 !== 'undefined') formData.append('file', key1)
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const key of this.formData?.tags) {
        if (typeof key !== 'undefined') formData.append('tags', key)
      }
      // eslint-disable-next-line no-unused-expressions
      this.formData?.title && formData.append('title', this.formData?.title)
      // eslint-disable-next-line no-unused-expressions
      this.formData?.comment && formData.append('comment', this.formData?.comment)
      if (this.formData?.action_type) {
        formData.append('action_type', this.formData?.action_type)
      }
      formData.append('author', this.getUserId)
      formData.append('content_type', this.ipValue.CONTENT_TYPE)
      formData.append('object_id', this.ipValue.id)
      formData.append('id', value)
      this.UPDATE_DEBT_DOCUMENT(formData)
        .then(() => {
          this.docRefresh()
          this.$bvModal.hide('addEdiClaimstDoc')
          this.$_okToast()
          this.hideModal()
        })
        .catch(err => {
          this.$_errorToast(err)
        })
    },

    hideModal() {
      this.$nextTick(() => {
        this.formData.date_document = null
        this.formData.title = ''
        this.formData.file = null
        this.formData.action_type = null
        this.formData.comment = ''
        this.formData.tags = []
        this.$bvModal.hide('addEdiClaimstDoc')
      })
    },

    deleteDataDocument(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_DEBT_DOCUMENT(id)
              .then(() => {
                this.docRefresh()
                this.$_okToast()
              })
              .catch(err => {
                this.$_errorToast(err)
              })
          }
        })
    },

    docRefresh(data) {
      this.FETCH_DEBT_DOCUMENT({
        content_type: this.ipValue.CONTENT_TYPE,
        object_id: this.ipValue.id,
        ...data,
      })
    },
    // ******************** document end **************************//
  },
}
</script>
