<template>
  <div>
    <BModal
      id="addEdiClaimstDoc2"
      centered
      :title="editDoc.id ? 'Редактировать документы' : 'Добавить документы'"
      :ok-variant="editDoc.id ? 'warning' : 'primary'"
      ok-title="Сохранить"
      cancel-variant="outline-secondary"
      cancel-title="Отмена"
      responsive
      no-close-on-backdrop
      @show="openShowModal"
      @ok="createDoc"
      @hidden="$emit('hideModal')"
    >
      <validation-observer ref="validation">
        <b-form-group label="Дата документа">
          <validation-provider
            #default="{ errors }"
            name="Дата документа"
            rules="required"
          >
            <datepicker
              id="passport_expire_date"
              v-model="formData.date_document"
              :bootstrap-styling="true"
              :clear-button="true"
              :language="ru"
              :calendar-button="true"
              format="dd.MM.yyyy"
              placeholder="ДД.ММ.ГГГГ"
              calendar-button-icon="fa fa-calendar"
              clear-button-icon="fa fa-times"
              input-class="bg-white"
              @input="onSelectDate"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Вид документ">
          <div class="d-flex align-items-center">
            <v-select
              v-model="formData.tags"
              class="w-100"
              multiple
              label="name"
              :reduce="p => p.id"
              :options="utilsDocType.results"
            >
              <template #no-options>
                К сожалению, нет подходящих вариантов
              </template>
            </v-select>

            <b-button
              class="ml-50"
              size="sm"
              variant="success"
              @click="openModal"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>
        </b-form-group>

        <b-form-group label="Файлы">
          <div class="d-flex align-items-center">
            <BFormFile
              ref="file-input"
              v-model="formData.file"
              multiple
              :placeholder="formData.file_title ? formData.file_title : 'Выбрать файл'"
            />
            <b-button
              size="sm"
              class="p-50 ml-50"
              variant="danger"
              @click="clearFiles"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </b-form-group>

        <b-form-group label="Полезная информация">

          <BFormInput
            v-model="formData.title"
          />
        </b-form-group>

        <b-form-group label="Комментарий">
          <b-form-textarea
            v-model="formData.comment"
          />
        </b-form-group>
      </validation-observer>
    </BModal>

    <typeDocument
      :modal-id="modalId"
      :type="type"
    />
  </div>
</template>

<script>
import {
  BModal,
  BFormFile,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { ru } from 'vuejs-datepicker/dist/locale'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment/moment'
import VSelect from 'vue-select'
import typeDocument from '@/components/typeDocument/index.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddEditModal',
  components: {
    BModal,
    BFormFile,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    Datepicker,
    VSelect,
    typeDocument,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    editDoc: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      ru,
      modalId: 'modalId3',
      // type: 'Претензии',
    }
  },
  computed: {
    ...mapState('reference', ['utilsDocType']),
  },
  mounted() {

  },
  methods: {
    ...mapActions('reference', ['FETCH_UTILS_DOC_TYPE']),

    openModal() {
      this.$nextTick(() => {
        this.$bvModal.show(this.modalId)
      })
    },

    clearFiles() {
      this.$refs['file-input'].reset()
    },

    onSelectDate(value) {
      if (value) {
        this.formData.date_document = moment(value).format('YYYY-MM-DD')
      }
    },

    createDoc(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.validation.validate()
        .then(success => {
          if (success) {
            if (this.editDoc.id) {
              this.$emit('editDocuments')
              this.$emit('hideModal')
            } else {
              this.$emit('createDoc')
              this.$emit('hideModal')
            }
          }
        })
    },
    openShowModal() {
      this.FETCH_UTILS_DOC_TYPE({
        page_size: 1000,
        type: this.type,
      })
      if (typeof this.editDoc === 'object' && this.editDoc !== null) {
        this.formData.date_document = this.editDoc.date_document
        this.formData.title = this.editDoc.title
        this.formData.file_title = this.editDoc.file_title
        this.formData.comment = this.editDoc.comment
        if (this.editDoc.id) {
          this.editDoc.tags.forEach(item => {
            this.formData.tags.push(item)
          })
        } else {
          this.formData.tags = []
        }
      } else {
        this.formData.file_title = ''
        this.formData.date_document = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
