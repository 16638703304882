<template>
  <div>
    <BCollapse v-model="collapseState">
      <ValidationObserver ref="userValidation">
        <b-row>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Название"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Название"-->
              <!--                rules="required"-->
              <!--              >-->
              <BFormInput
                v-model="formData.title"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Вид действия"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Вид действия"
                rules="required"
              >
                <VSelect
                  v-model="formData.action_type"
                  label="name"
                  :reduce="(p) => p.id"
                  :options="document_types.results"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Вид работ"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Вид работ"-->
              <!--                rules="required"-->
              <!--              >-->
              <VSelect
                v-model="formData.work_type"
                label="name"
                :reduce="(p) => p.id"
                :options="forms.results"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Контрольная дата"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Контрольная дата"-->
              <!--                rules="required"-->
              <!--              >-->
              <VcDatePicker
                v-model="formData.planned_time"
                show-format="YYYY.MM.DD"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>

          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Ответственный"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Ответственный"-->
              <!--                rules="required"-->
              <!--              >-->
              <VSelect
                v-model="formData.participants"

                :get-option-label="el => {
                  return el.first_name + ' ' + el.last_name
                }"
                :reduce="p => p.id"
                :options="userList.results"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>

          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Задание"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Ответственный"-->
              <!--                rules="required"-->
              <!--              >-->
              <BFormInput
                v-model="formData.task_name"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Трек номер"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Задание"-->
              <!--                rules="required"-->
              <!--              >-->
              <BFormInput v-model="formData.track_number" />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>

          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup
              label="Идентификатор"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Идентификатор"-->
              <!--                rules="required"-->
              <!--              >-->
              <BFormInput
                v-model="formData.identifier"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup label="Ответ">
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Ответ"-->
              <!--                rules="required"-->
              <!--              >-->
              <BFormInput v-model="formData.answer" />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup label="Дата ответа">
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Дата ответа"-->
              <!--                rules="required"-->
              <!--              >-->
              <VcDatePicker
                v-model="formData.answer_date"
                show-format="YYYY.MM.DD"
              />
              <!--              {{ formData.answer_date }}-->
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup label="Автор">
              <BFormInput
                v-model="getUserName.first_name"
                readonly
              />
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="3"
          >
            <BFormGroup label="Документы">
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Документы"-->
              <!--                rules="required"-->
              <!--              >-->
              <BFormFile
                v-model="formData.file"
                placeholder="Файл не выбран(ы)"
                multiple
                drop-placeholder="Перетащите файлы сюда"
                browse-text="Выбрать"
              />
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <BCol
            sm="6"
            md="6"
          >
            <BFormGroup
              label="Комментарий"
            >
              <!--              <ValidationProvider-->
              <!--                #default="{ errors }"-->
              <!--                name="Комментарий"-->
              <!--                rules="required"-->
              <!--              >-->
              <b-form-textarea
                v-model="formData.description"
                rows="3"
              />
              <!--              <BFormInput v-model="formData.description" />-->
              <!--                <small class="text-danger">{{ errors[0] }}</small>-->
              <!--              </ValidationProvider>-->
            </BFormGroup>
          </BCol>
          <b-col>
            <div class="d-flex flex-column justify-content-start mt-1">
              <label>Поручитель</label>
              <b-form-checkbox
                v-model="formData.is_guarantor"
                checked="true"
                name="check-button"
                switch
                inline
              />
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
      <div class="d-flex justify-content-end footer-collapse">
        <BButton
          class="mr-1"
          @click="clear"
        >
          Закрыть
        </BButton>
        <BButton
          variant="primary"
          @click="onSubmit"
        >
          Сохранить
        </BButton>
      </div>
    </BCollapse>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCollapse,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import VcDatePicker from '@/components/VcDatePicker.vue'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormTextarea,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCollapse,
    VSelect,
    VcDatePicker,
    BFormCheckbox,
  },
  data() {
    return {
      collapseState: false,
      formData: {
        title: 'Действия',
        action_type: '',
        work_type: '',
        planned_time: '',
        answer_date: '',
        participants: '',
        description: '',
        task_name: '',
        is_guarantor: '',
        track_number: '',
        identifier: '',
        answer: '',
        file: null,
        task_group: 1,
      },
    }
  },
  computed: {
    ...mapState('reference', ['document_types', 'forms']),
    ...mapState('users', ['userList', 'userDetail']),
    ...mapState('cases', ['debtListId']),

    getUserName() {
      return useJwt.getUserData()
    },
  },
  mounted() {
    // eslint-disable-next-line camelcase
    const page_size = {
      page_size: 1000,
    }
    this.FETCH_DOCUMENT_TYPES(page_size)
    this.FETCH_WORK_TYPE(page_size)
    this.FETCH_ALL_USERS(page_size)
    this.FETCH_ONE_USER({ id: this.getUserName.id })
      .then(() => {
        this.formData.participants = this.getUserName.id
        this.userDetail.roles.map(res => {
          this.forms.results.map(userRes => {
            if (res.name === userRes.name) {
              this.formData.work_type = res.id
            }
          })
        })
      })
    // console.clear()
  },
  methods: {
    ...mapActions('reference', ['FETCH_DOCUMENT_TYPES', 'FETCH_WORK_TYPE']),
    ...mapActions('users', ['FETCH_ALL_USERS', 'FETCH_ONE_USER']),
    ...mapActions('actions', ['CREATE_DEBT_ACTION', 'FETCH_DEBT_ACTION']),

    open() {
      this.collapseState = true
    },

    async onSubmit() {
      const req = await this.$refs.userValidation.validate()

      if (!req) return
      try {
        const formData = new FormData()
        formData.append('title', this.formData.title)
        formData.append('is_guarantor', this.formData.is_guarantor)
        formData.append('action_type', this.formData.action_type)
        formData.append('work_type', this.formData.work_type)
        if (this.formData.planned_time) {
          formData.append('planned_time', this.formData.planned_time)
        }
        formData.append('participants', this.formData.participants)
        formData.append('description', this.formData.description)
        formData.append('task_name', this.formData.task_name)
        formData.append('track_number', this.formData.track_number)
        formData.append('identifier', this.formData.identifier)
        formData.append('answer', this.formData.answer)
        if (this.formData.answer_date) {
          formData.append('answer_date', this.formData.answer_date)
        }
        if (this.formData.file) {
          this.formData.file.forEach(item => {
            formData.append('file', item)
          })
        }
        formData.append('task_group', this.formData.task_group)
        formData.append('content_type', this.debtListId.CONTENT_TYPE)
        formData.append('object_id', this.$route.params.id)
        await this.CREATE_DEBT_ACTION(formData)
        await this.FETCH_DEBT_ACTION({
          content_type: this.debtListId.CONTENT_TYPE,
          object_id: this.$route.params.id,
          current_step: '1',
        })
        await this.$_okToast()
        this.clear()
      } catch (error) {
        await this.$_errorToast()
      }
    },

    clear() {
      this.collapseState = false
      this.FETCH_DEBT_ACTION({
        content_type: this.debtListId.CONTENT_TYPE,
        object_id: this.$route.params.id,
        current_step: '1',
      })
        .then(() => {
          this.formData.participants = this.getUserName.id
          // eslint-disable-next-line array-callback-return
          this.userDetail.roles.map(res => {
            // eslint-disable-next-line array-callback-return
            this.forms.results.map(userRes => {
              if (res.name === userRes.name) {
                this.formData.work_type = res.id
              }
            })
          })
        })
      this.formData = {
        title: 'Действия',
        action_type: '',
        work_type: '',
        planned_time: '',
        participants: '',
        description: '',
        task_name: '',
        is_guarantor: false,
        track_number: '',
        identifier: '',
        answer: '',
        answer_date: '',
        file: null,
        task_group: 1,
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>
