<template>
  <div>
    <div class="w-100 bg-light p-1 mb-2 rounded d-flex justify-content-between">
      <p class="mb-0">
        Суд
      </p>

      <!--      <p class="mb-0">-->
      <!--        <BButton-->
      <!--          style="padding: 3px"-->
      <!--          :variant="!editItem ? 'warning' : 'light'"-->
      <!--          class="rounded-circle mr-50"-->
      <!--          @click="editItem = !editItem"-->
      <!--        >-->
      <!--          <feather-icon icon="EditIcon" />-->
      <!--        </BButton>-->
      <!--      </p>-->
    </div>
    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <!--        <div class="filds__nav">-->
        <!--          <p>Суд:</p>-->
        <!--          <b-workType-input-->
        <!--            value="court"-->
        <!--            :plaintext="editItem"-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div class="filds__nav">-->
        <!--          <p>Судья:</p>-->
        <!--          <b-workType-input-->
        <!--            value="judge"-->
        <!--            :plaintext="editItem"-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div class="filds__nav">-->
        <!--          <p>Отмена СП:</p>-->
        <!--          <b-workType-input-->
        <!--            value="revocation_sp"-->
        <!--            :plaintext="editItem"-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div class="filds__nav">-->
        <!--          <p>Судебные определения:</p>-->
        <!--          <p-->
        <!--            class="a__click"-->
        <!--            @click="openModal('courtRulings')"-->
        <!--          >-->
        <!--            court_rulings-->
        <!--          </p>-->
        <!--        </div>-->
        <!--{{SkDebtAdjudgement}}-->
        <div class="filds__nav">
          <p>Акт от {{ SkDebtAdjudgement.DOCUMENT_DATE }}:</p>
          <p
            class="a__click"
            @click="openActModal"
          >
            {{ SkDebtAdjudgement.DOCUMENT_DATE }}
          </p>
          <!--          @click="openModal('judicalAct')"-->
        </div>

        <!--        <div class="filds__nav">-->
        <!--          <p>Добавить судебный акт:</p>-->
        <!--          <b-workType-input-->
        <!--            value="add_judicial_act"-->
        <!--            :plaintext="editItem"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>
    <TheCourtRulingsModal ref="courtRulings" />
    <!--    -->
    <!--    <TheJudicialAct ref="judicalAct" />-->
    <!--    -->
    <judicalActModal
      @refresh="refresh"
    />
  </div>
</template>

<script>
// import { BButton, BFormInput } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import judicalActModal from '@/modules/claimsLitigation/adjugment/judicalActModal.vue'
import TheCourtRulingsModal from './TheCourtRulingsModal.vue'
// import TheJudicialAct from './TheJudicialAct.vue'

export default {
  components: {
    // BButton,
    // BFormInput,
    TheCourtRulingsModal,
    // TheJudicialAct,
    judicalActModal,
  },
  props: {
    current: {
      type: Number,
    },
  },
  data() {
    return {
      editItem: true,
    }
  },
  computed: {
    ...mapState('cases', ['SkDebtAdjudgement']),
  },
  watch: {
    current: {
      handler(val) {
        this.FETCH_SK_DEBT_ADJUDGEMENT({ id: val })
      },
    },
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT_ADJUDGEMENT']),

    refresh() {
      this.FETCH_SK_DEBT_ADJUDGEMENT({ id: this.current })
    },

    openModal(modal) {
      const enm = ['judicalAct', 'courtRulings']
      if (enm.includes(modal)) {
        this.$refs[modal].open()
      } else {
        console.error('Нет такого модального окна ', JSON.stringify(modal))
      }
    },

    openActModal() {
      this.$nextTick(() => {
        this.$bvModal.show('judicalActId')
      })
    },
  },
}
</script>
