<template>
  <b-modal
    id="modal-acction"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    ok-title="Сохранить"
    ok-variant="success"
    cancel-title="Отменить"
    centered
    size="lg"
    title="Редактировать вид действия"
    @show="showModal"
    @ok.prevent="onSubmitDoc"
  >
    <ValidationObserver ref="actionValidation">
      <BRow>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Название:"
          >
            <BFormInput
              v-model="formData.title"
            />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Вид действия"
          >
            <ValidationProvider
              #default="{ errors }"
              name="Вид действия"
              rules="required"
            >
              <VSelect
                v-model="formData.action_type"
                label="name"
                :options="document_types.results"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Вид работ"
          >
            <VSelect
              v-model="formData.work_type"
              label="name"
              :options="forms.results"
            />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Контрольная дата"
          >
            <VcDatePicker
              v-model="formData.planned_time"
              show-format="YYYY.MM.DD"
            />
          </BFormGroup>
        </BCol>

        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Ответственный"
          >
            <VSelect
              v-model="formData.participants"
              label="full_name"
              :reduce="p => p.id"
              :options="userList.results"
            />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Комментарий:"
          >
            <BFormInput v-model="formData.description" />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Задание:"
          >
            <BFormInput
              v-model="formData.task_name"
            />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Трек номер:"
          >
            <BFormInput v-model="formData.track_number" />
          </BFormGroup>
        </BCol>

        <BCol
          class="col-md-6"
        >
          <BFormGroup
            label="Идентификатор:"
          >
            <BFormInput
              v-model="formData.identifier"
            />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup label="Ответ:">
            <BFormInput v-model="formData.answer" />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup label="Дата ответа">
            <VcDatePicker
              v-model="formData.answer_date"
              show-format="YYYY.MM.DD"
            />
          </BFormGroup>
        </BCol>
        <BCol
          class="col-md-6"
        >
          <BFormGroup label="Документы">
            <BFormFile
              v-model="formData.file"
              placeholder="Файл не выбраны"
              multiple
              drop-placeholder="Перетащите файлы сюда"
              browse-text="Выбрать"
            />
          </BFormGroup>
        </BCol>

        <b-col>
          <b-form-checkbox
            v-model="formData.is_guarantor"
            checked="true"
            name="check-button"
            switch
            inline
          >
            Поручитель
          </b-form-checkbox>
        </b-col>
      </BRow>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {
  BFormFile,
  BModal,
  VBModal,
  BFormInput,
  BFormGroup, BRow, BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'Modal',
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BFormFile,
    VSelect,
    BRow,
    BCol,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    editItemAction1: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        title: '',
        action_type: '',
        work_type: '',
        planned_time: '',
        participants: '',
        description: '',
        task_name: '',
        track_number: '',
        identifier: '',
        answer: '',
        answer_date: '',
        file: null,
        task_group: 1,
        is_guarantor: false,
      },
    }
  },
  computed: {
    ...mapState('reference', ['document_types', 'forms']),
    ...mapState('users', ['userList', 'userDetail']),
    ...mapState('cases', ['debtListId']),

    getUserName() {
      return useJwt.getUserData()
    },
  },
  methods: {
    ...mapActions('actions', ['UPDATE_DEBT_ACTION', 'FETCH_DEBT_ACTION']),
    ...mapActions('users', ['FETCH_ONE_USER']),
    async onSubmitDoc() {
      const req = await this.$refs.actionValidation.validate()
      if (!req) return

      try {
        const formData = new FormData()
        formData.append('title', this.formData.title)
        if (this.formData.action_type) {
          formData.append('action_type', this.formData.action_type.id)
        }
        if (this.formData.work_type) {
          formData.append('work_type', this.formData.work_type.id)
        }
        if (this.formData.planned_time) {
          formData.append('planned_time', this.formData.planned_time)
        }
        formData.append('participants', this.formData.participants)
        formData.append('description', this.formData.description)
        formData.append('task_name', this.formData.task_name)
        formData.append('track_number', this.formData.track_number)
        formData.append('identifier', this.formData.identifier)
        formData.append('answer', this.formData.answer)
        if (this.formData.answer_date) {
          formData.append('answer_date', this.formData.answer_date)
        }
        formData.append('is_guarantor', this.formData.is_guarantor)
        formData.append('task_group', this.formData.task_group)
        formData.append('content_type', this.debtListId.CONTENT_TYPE)
        formData.append('current_step', this.editItemAction1?.current_step.id)
        // formData.append('id', this.editItemAction1.id)
        if (this.formData.file) {
          this.formData.file.forEach(item => {
            formData.append('file', item)
          })
        }
        await this.UPDATE_DEBT_ACTION({ id: this.editItemAction1.id, data: formData })
        await this.FETCH_DEBT_ACTION({
          content_type: this.debtListId.CONTENT_TYPE,
          object_id: this.$route.params.id,
          current_step: '1',
        })
        this.$bvModal.hide('modal-acction')
        await this.$_okToast()
      } catch (error) {
        await this.$_errorToast()
      }
    },

    showModal() {
      this.FETCH_ONE_USER({ id: this.getUserName.id })
        .then(() => {
          // this.formData.participants = this.getUserName.id
          // eslint-disable-next-line array-callback-return
          this.userDetail.roles.map(res => {
            // eslint-disable-next-line array-callback-return
            this.forms.results.map(userRes => {
              if (res.name === userRes.name) {
                this.formData.work_type = res
              }
            })
          })
        })
      if (typeof this.editItemAction1 === 'object' && this.editItemAction1 !== null) {
        this.formData.title = this.editItemAction1.title
        this.formData.work_type = this.editItemAction1.work_type
        this.formData.action_type = this.editItemAction1.action_type
        this.formData.planned_time = this.editItemAction1.planned_time
        this.formData.participants = this.editItemAction1.participants.map(e => e.user_id)
        this.formData.description = this.editItemAction1.description
        this.formData.task_name = this.editItemAction1.task_name
        this.formData.track_number = this.editItemAction1.track_number
        this.formData.answer = this.editItemAction1.answer
        this.formData.answer_date = this.editItemAction1.answer_date
        this.formData.is_guarantor = this.editItemAction1.is_guarantor
        this.formData.identifier = this.editItemAction1.identifier
      }
    },
  },
}
</script>
