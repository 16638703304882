<template>
  <div>
    <div class="rounded border w-100 p-1">
      <BButton
        v-for="(item, index) in skDebtJudicalWork.results"
        :key="index"
        class="border mr-1"
        variant="light"
        :class="{'activeList' : current === item.id}"
        @click.stop="filteredDate(item, item.id)"
      >
        Работа от {{ item.PETITION_DATE }}
      </BButton>
    </div>
    <BRow>
      <BCol md="6">
        <TheJudicialWork />
      </BCol>
      <BCol md="6">
        <TheAmounts />
        <TheCourt
          :current="current"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import TheJudicialWork from '../components/TheJudicialWork.vue'
import TheAmounts from '../components/TheAmounts.vue'
import TheCourt from '../components/TheCourt.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    TheJudicialWork,
    TheAmounts,
    TheCourt,
  },
  data() {
    return {
      current: null,
    }
  },
  computed: {
    ...mapState('cases', ['skDebtJudicalWork']),
  },
  mounted() {
    this.FETCH_SK_DEBT_JUDICAL_WORK({ id: this.$route.params.id })
      .then(() => {
        if (this.skDebtJudicalWork.results.length) {
          this.filteredDate({ id: this.skDebtJudicalWork.results?.[0].id }, this.skDebtJudicalWork.results?.[0].id)
        } else {
          this.SET_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({ results: [] })
        }
      })
    // console.clear()
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT_JUDICAL_WORK', 'FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE']),
    ...mapMutations('cases', ['SET_SK_DEBT_JUDICAL_WORK_FILTERED_DATE']),

    filteredDate(item, id) {
      this.current = id
      this.FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({ id: item.id })
    },
  },
}
</script>

<style>
.activeList {
  background: #2639BB !important;
  color: #fff !important;
}

.activeList svg:nth-child(1) {
  color: #fff;
}
</style>
