<template>
  <div>
    <div class="w-100 bg-light p-1 mb-2 rounded d-flex justify-content-between">
      <p class="mb-0">
        Исполнительное производство
      </p>
    </div>
    <div
      v-for="(value, name) in skDebtCcpIp.results"
      :key="name"
      class="d-flex w-100"
    >
      <div class="w-100 text-right pr-1 text-center d-flex justify-content-start mb-1 ml-3">
        <p>ИП от {{ value.SSP_IP_START_DATE }}:</p>
        <p
          class="ml-1 a__click"
          @click="openIpModal(value)"
        >
          Открыть
        </p>
      </div>
    </div>

    <ipModal
      :ip-value="ipValue"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ipModal from '@/modules/ssp/modal/ipModal.vue'

export default {
  components: {
    ipModal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    current: {
      type: Number,
    },
  },
  data() {
    return {
      ipValue: {},
    }
  },
  computed: {
    ...mapState('cases', ['skDebtCcpIp']),
  },
  watch: {
    current: {
      handler(val) {
        this.FETCH_SK_DEBT_IP({ id: val })
      },
    },
  },
  mounted() {
    this.FETCH_SK_DEBT_IP({ id: this.current })
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT_IP']),

    openModal(modal = '') {
      return () => this.$refs[modal].open()
    },

    refresh() {
      this.FETCH_SK_DEBT_IP({ id: this.current })
    },

    openIpModal(value) {
      this.ipValue = value
      this.$nextTick(() => {
        this.$bvModal.show('ipModal')
      })
    },
  },
}
</script>
