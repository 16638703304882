<template>
  <div>
    <div class="w-100 bg-light p-1 mb-2 rounded d-flex justify-content-between">
      <p class="mb-0">
        Суммы
      </p>
      <p class="mb-0">
        <BButton
          v-if="skDebtCcpButtonFilter.CONTENT_TYPE"
          style="padding: 3px"
          :variant="!editItem ? 'warning' : 'light'"
          class="rounded-circle mr-50"
          @click="editItem = !editItem"
        >
          <feather-icon icon="EditIcon" />
        </BButton>
      </p>
    </div>
    <div class="d-flex p-1">
      <div class="fields px-1 w-100">
        <div class="filds__nav">
          <p>Сумма по ИД:</p>
          <b-form-input
            v-model="skDebtCcpButtonFilter.EXECUTIVE_SUM"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма остатка:</p>
          <b-form-input
            v-model="skDebtCcpButtonFilter.REST_SUM"
            :plaintext="editItem"
          />
        </div>

        <div class="filds__nav">
          <p>Сумма остатка с учётом длящихся:</p>
          <b-form-input
            v-model="skDebtCcpButtonFilter.REST_SUM_LONG"
            :plaintext="editItem"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!editItem"
      class="text-center mb-1"
    >
      <b-button
        variant="danger"
        @click="editItem = true"
      >
        Отменить
      </b-button>
      {{ '' }}
      <b-button
        variant="primary"
        @click="updateCcpSum"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BButton,
    BFormInput,
  },

  data() {
    return {
      editItem: true,
    }
  },

  computed: {
    ...mapState('cases', ['skDebtCcpButtonFilter']),
  },

  methods: {
    ...mapActions('cases', ['UPDATE_SK_DEBT_CCP_SUM']),
    updateCcpSum() {
      delete this.skDebtCcpButtonFilter.modified_date
      this.UPDATE_SK_DEBT_CCP_SUM({
        id: this.skDebtCcpButtonFilter.id,
        EXECUTIVE_SUM: this.skDebtCcpButtonFilter.EXECUTIVE_SUM,
        REST_SUM: this.skDebtCcpButtonFilter.REST_SUM,
        REST_SUM_LONG: this.skDebtCcpButtonFilter.REST_SUM_LONG,
      })
        .then(() => {
          this.editItem = true
          this.$_okToast()
        })
    },
  },
}
</script>
