<template>
  <BModal
    ref="modal"
    size="xl"
    centered
    title="Судебные определения"
    ok-variant="info"
    ok-title="Да"
    cancel-title="Отмена"
    hide-footer
    responsive
    no-close-on-backdrop
  >
    <div style="overflow-x: auto">
      <BTable
        class="my-2"
        :items="courtRulingsList"
        :fields="fields"
        show-empty
        empty-text="Нет данных"
      >
        <template #cell(date)="props">
          <VcDatePicker
            v-if="checkItemId(props.item)"
            v-model="formData.date"
            show-format="YYYY.MM.DD"
          />
          <span v-else>{{ props.item.date }}</span>
        </template>
        <template #cell(date_doc)="props">
          <VcDatePicker
            v-if="checkItemId(props.item)"
            v-model="formData.date_doc"
            show-format="YYYY.MM.DD"
          />
          <span v-else>{{ props.item.date_doc }}</span>
        </template>
        <template #cell(type)="props">
          <VSelect
            v-if="checkItemId(props.item)"
            v-model="formData.type"
            class="select-w-z-index"
            :clearable="false"
            :options="types"
            label="name"
          />
          <span v-else> {{ props.item.on_whom }}</span>
        </template>
        <template #cell(documents)="props">
          <p
            class="hover-text-underline"
            @click="openDocumentsModal"
          >
            Документы
          </p>
        </template>

        <template #cell(date_submitted)="props">
          <VcDatePicker
            v-if="checkItemId(props.item)"
            v-model="formData.date_submitted"
            show-format="YYYY.MM.DD"
          />
          <span v-else>{{ props.item.date_submitted }}</span>
        </template>

        <template #cell(comment)="props">
          <BFormTextarea
            v-if="checkItemId(props.item)"
            v-model="formData.comment"
            :rows="3"
            :max-rows="3"
            :resize="false"
          />
          <span v-else>{{ props.item.comment }}</span>
        </template>
        <template #cell(rejections)="props">
          <VSelect
            v-if="checkItemId(props.item)"
            v-model="formData.rejections"
            class="select-w-z-index"
            :clearable="false"
            :options="rejections_list"
            label="name"
          />
          <span v-else> {{ props.item.on_whom }}</span>
        </template>

        <template #cell(actions)="props">
          <div v-if="props.item.id !== formData.id">
            <BButton
              class="p-50 mr-1"
              variant="warning"
              @click="editRow(props.item)"
            >
              <feather-icon icon="EditIcon" />
            </BButton>
            <BButton
              class="p-50"
              variant="danger"
              @click="deleteData"
            >
              <feather-icon icon="XIcon" />
            </BButton>
          </div>
        </template>
      </BTable>
    </div>

    <div class="d-flex justify-content-center d-none">
      <BButton
        v-if="!formData.active"
        class="mr-2"
        variant="primary"
        @click="addNewData"
      >
        <feather-icon
          icon="PlusIcon"
        />
        Добавить имущество
      </BButton>
      <div v-else>
        <BButton
          class="mr-2"
          variant="warning"
          @click="clearData"
        >
          Сохранить
        </BButton>
        <BButton
          class="border"
          variant="light"
          @click="clearData"
        >
          Отменить
        </BButton>
      </div>
    </div>

    <TheDocumentsModal ref="documents" />
  </BModal>
</template>

<script>
import {
  BButton,
  BModal,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormFile,
  BTable,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import VcDatePicker from '@/components/VcDatePicker.vue'
import { validate } from 'vee-validate'

import TheDocumentsModal from '@components/modules/TheDocumentsModal.vue'

const defData = {
  date: 'date',
  date_doc: 'date_doc',
  type: 'type',
  documents: 'documents',
  date_submitted: 'date_submitted',
  comment: 'comment',
  rejections: 'rejections',
}

export default {
  name: 'TheDebtorIncomeModal',
  components: {
    BButton,
    BModal,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormFile,
    BTable,
    VSelect,
    VcDatePicker,
    TheDocumentsModal,
  },
  data() {
    return {
      formData: {
        active: false,
      },
      fields: [
        //   Дата, Дата слушания, Вид, Документы, Дата предоставления документа, Комментарий, Обжалования, Действия
        {
          label: 'Дата',
          key: 'date',
        },
        {
          label: 'Дата слушания',
          key: 'date_doc',
        },
        {
          label: 'Вид',
          key: 'type',
        },
        {
          label: 'Документы',
          key: 'documents',
        },
        {
          label: 'Дата предоставления документа',
          key: 'date_submitted',
        },
        {
          label: 'Комментарий',
          key: 'comment',
        },
        {
          label: 'Обжалования',
          key: 'rejections',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      courtRulingsList: [
        {
          id: 1,
          date: 'date',
          date_doc: 'date_doc',
          type: 'type',
          files: 'files',
          date_submitted: 'date_submitted',
          comment: 'comment',
          rejections: 'rejections',
        },
      ],

      types: [
        {
          name: 'Принято к производству',
          value: 'Принято к производству',
        },
      ],
      rejections_list: [
        {
          name: 'Отказ',
          value: 'Отказ',
        },
      ],
    }
  },

  mounted() {
    this.courtRulingsList.forEach(item => {
      item.edit = false
    })
  },

  methods: {
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },
    openDocumentsModal() {
      this.$refs.documents.open()
    },
    editRow(item) {
      this.clearEptyObj()
      this.formData = {
        active: true,
        ...item,
      }
    },

    checkItemId(item) {
      return item.id === this.formData.id
    },

    deleteData() {
      this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить эту запись?',
        {
          title: 'Удалить',
          okVariant: 'danger',
          okTitle: 'Подтвердить',
          cancelTitle: 'Отменить',
          hideHeaderClose: false,
          centered: true,
        },
      )
    },
    addNewData() {
      this.formData = {
        id: 0,
        active: true,
        ...defData,
      }
      this.courtRulingsList.push({
        id: 0,
        active: true,
        ...defData,
      })
    },
    clearEptyObj() {
      this.courtRulingsList = this.courtRulingsList.filter(e => parseInt(e.id))
    },
    clearData() {
      this.clearEptyObj()
      this.formData = {
        active: false,
        ...defData,
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.select-w-z-index {
  min-width: 250px;
  z-index: 9999;
}
</style>
